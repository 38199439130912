import { ref } from 'vue';
import { defineStore } from 'pinia'
import { getUserInfo as _getUserInfo, logout, getStoreDetail as _getStoreDetail, getRouter,postIMOutLogin } from '@/common/request/api'
import type { UserStore, UserInfo, StoreDetail } from '@/common/request/api'
import {AppRouterMap} from '@/env'
import { AppCookie } from '@/common/utils'
import IM from '@/common/utils/IM/IM'

export type AppRouter = {
	marketing:string[]
}

export const useUser = defineStore('user', () => {
	const isLogin = ref(false)
	const userInfo = ref<UserInfo>(uni.getStorageSync('userInfo') ? uni.getStorageSync('userInfo') : {})
	const userStoreInfo = ref<UserStore>(uni.getStorageSync('userStoreInfo') ? uni.getStorageSync('userStoreInfo') : {})
	const storeDetail = ref<StoreDetail>({} as StoreDetail)
	const homeNumberScrollState = ref(true)
	const appRouter = ref<AppRouter>({
		marketing:[]
	})
	const setLogin = (type: boolean) => {
		isLogin.value = type
	}
	const setUserInfo = (info: any) => {
		userInfo.value = info
	}
	const setUserStoreInfo = (info: UserStore) => {
		userStoreInfo.value = info
	}
	const setStoreDetail = (info: StoreDetail) => {
		storeDetail.value = info
	}
	const setHomeNumberScrollState = (type: boolean) => {
		homeNumberScrollState.value = type
	}
	const setAppRouter = (data:AppRouter) => {
		appRouter.value = data
	}
	const getUserInfo = async () =>  {
		const res = await _getUserInfo()
		setLogin(true);
		setUserInfo(res.data);
		uni.setStorageSync('userInfo', res.data);
		return res
	}
	const getStoreDetail = async () => {
		const res = await _getStoreDetail()
		setStoreDetail(res.data);
		return res
	}
	const getUserRouter = () => new Promise((resolve, reject) => {
		getRouter({ storeId: userStoreInfo.value.id }).then(res => {
			resolve(res)
			if (res.code === 200) {
				let appRouterList = res.data.find(item => item.name === 'application')
				if (appRouterList && appRouterList.children) {
					let router = appRouter.value
					let marketing = appRouterList.children.find(item => item.name === 'marketing')
					if (marketing && marketing.children) {
						let arr:string[] = []
						marketing.children.forEach(item => {
							if(AppRouterMap.marketing.includes(item.name)){
								arr.push(item.name)
							}
						})
						router.marketing = arr
					}
					setAppRouter(router)
				}

			}
			
		}).catch(e => {
			reject(e)
		})
	})
	const IMOutLogin = async () => {
		const tiocomConfig = uni.getStorageSync('IMConfig')
		if(AppCookie.get(tiocomConfig.session_cookie_name)){
			try{
				await postIMOutLogin()
			}catch(e){
				//TODO handle the exception
			}
		}
		IM.outLogin()
	}
	const outLogin = () => {
		logout()
		uni.removeStorageSync('token');
		uni.removeStorageSync('userInfo')
		uni.removeStorageSync('userStoreInfo')
		setLogin(false);
		setUserInfo({} as UserInfo);
		IMOutLogin()
	}
	

	return {
		isLogin,
		userInfo,
		userStoreInfo,
		homeNumberScrollState,
		appRouter,
		storeDetail,
		setStoreDetail,
		setLogin,
		setUserInfo,
		setUserStoreInfo,
		setHomeNumberScrollState,
		getUserInfo,
		getStoreDetail,
		getUserRouter,
		outLogin,
		IMOutLogin
	}
})
