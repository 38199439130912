export default {
	viewAll:'查看全部',
	empty:'暫無記錄',
	noCard:'您當前還未綁定銀行卡',
	noBinding:'暫未添加綁定銀行卡',
	binding:'+去綁定',
	addCard:'綁定新銀行卡',
	isDelCard:'確定要解除綁定此銀行卡嗎？',
	cardLabel1:'持卡人',
	cardLabel2:'身份證',
	cardLabel3:'開戶行',
	cardLabel4:'卡號',
	cardLabel5:'手機號',
	cardTips1:'請輸入持卡人姓名',
	cardTips2:'請輸入身份證號碼',
	cardTips3:'請輸入選擇開戶行',
	cardTips4:'請輸入銀行卡卡號',
	cardTips5:'請輸入銀行預留手機號碼',
	nowBinding:'立即綁定',
	selectCard:'選擇到賬銀行卡',
	extractCard:'到賬銀行卡',
	nowExtract:'立即提現',
	allExtract:'全部提現',
	userMoney:'可提現餘額',
	extractMoney:'提現金額',
	extractFee:'提現手續費',
	extractTips1:'請選擇銀行卡',
	extractTips2:'請輸入提現金額',
	extractTips3:'最低提現金額為',
	extractTips4:'最高提現金額為',
	extractSuccessTips1:'提現成功',
	extractSuccessTips2:'提現金額將會在1-3個工作日內到賬',
	extractStatus1:'稽核中',
	extractStatus2:'提現成功',
	extractStatus3:'稽核失敗',
	income:'收入',
	expend:'支出',
	balanceDetails:'餘額明細',
	frozenDetails:'凍結明細',
}