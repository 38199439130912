import coupon from './coupon'
import marketing from './marketing'
import trade from './trade'
import timesCard from './timesCard'
import cityAppointment from './cityAppointment'
import seckill from './seckill'
import group from './group'
export default {
	marketing,
	coupon,
	trade,
	timesCard,
	cityAppointment,
	seckill,
	group
}