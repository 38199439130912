export type Badge = {
	    isDot?: boolean,
	    value?: string|number,
	    show?: boolean,
	    max?: number|string,
	    type?: 'warning'|'success'|'primary'|'info'|'error',
	    showZero?: boolean,
	    bgColor?: string,
	    color?: string,
	    shape?: 'circle'|'horn',
	    numberType?: 'overflow'|'ellipsis'|'limit',
	    offset?: Array<number|string>,
	    inverted?: boolean,
	    absolute?: boolean,
		customStyle?: Record<string,any> |string,
		customClass?:string
	
};
export type BadgePropsType = Omit<Badge,'type'|'shape'|'numberType'>&{
	type?:string,
	shape?:string,
	numberType?:string,
}
const badge: Badge = {
	// badge组件
	    isDot: false,
	    value: '',
	    show: true,
	    max: 999,
	    type: 'error',
	    showZero: false,
	    shape: 'circle',
	    numberType: 'overflow',
	    offset:[0,10],
	    inverted: false,
	    absolute: false
};
export default badge;
