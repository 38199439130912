export default {
	loginTitle: '商家登入',
	forgetTitle: '忘記密碼',
	loginTips1: '請輸入您的手機號',
	loginTips2: '請輸入密碼',
	login: '登入',
	forgetTips1: '請輸入驗證碼',
	forgetTips2: '6-12比特字母與數位組合',
	forgetTips3: '請確認登入密碼',
	forgetTips4: '請輸入登入密碼',
	forgetTips5:'6比特純數位',
	forgetTips6:'請輸入驗證碼',
	pawTips1:'請確認支付密碼',
	pawTips2: '請輸入支付密碼',
	getCode: '獲取驗證碼',
	label1: '手機號',
	label2: '驗證碼',
	label3: '登入密碼',
	label4: '確認密碼',
	label5: '支付密碼',
	setPayPaw: '設定支付密碼',
	editPayPaw: '修改支付密碼',
	editLoginPaw: '修改登入密碼',
	nowVersion: '當前版本',
	delCache: '清除緩存',
	delCacheTips: '是否清除APP緩存？',
	delSuccess: '清除成功',
	versionIsNew: '當前版本為最新版本',
	upgradeTxt: '正在更新…',
	upgradeSuccess: '應用已更新，即將重啓',
	upgradeError: '安裝失敗，請重試',
	newVersion: '發現新版本',
	upgrade: '立即陞級',
	unread:'未讀消息：',
	allRead:'全部已讀',
	goRegisterTips:'還沒有帳號？ 立即注册>',
	goLoginTips:'已有帳號？ 立即登入>',
	registerTitle: '商家注册',
	agreementTxt1:'我已閱讀並同意',
	agreementTxt2:'《登錄服務協議》',
	agreementTxt3:'和',
	agreementTxt4:'《個人信息保護政策》',
	agreementTips:'請先閱讀並同意使用者協定',
	registerSuccess:'注册成功',
	forgetSuccess:'設定成功',
	switchStore:'切換店鋪',
	out:'登出',
	outTips:'確定要登出嗎？',
	noticeEmpty:'暫無消息',
	register:'注册'
}