import { AlignTypes } from '../props'

export type Search = {
	shape?: "round" | "square",
	bgColor?: string,
	placeholder?: string,
	clearabled?: boolean,
	focus?: boolean,
	showAction?: boolean,
	actionStyle?: Record<string, any>,
	actionText?: string,
	inputAlign?: AlignTypes,
	inputStyle?: Record<string, any>,
	disabled?: boolean,
	borderColor?: string,
	searchIconColor?: string,
	searchIconSize?: string | number,
	color?: string,
	placeholderClass?:string
	placeholderColor?: string,
	searchIcon?: string,
	margin?: string,
	animation?: boolean,
	modelValue?: string | number,
	maxlength?: string | number,
	height?: string | number,
	label?: string|number,
	customStyle?: Record<string,any>|string,
	customClass?:string
};
export type SearchPropsType = Search
const search: Search = {
	// search组件
	shape: 'round',
	bgColor: '#f2f2f2',
	clearabled: true,
	focus: false,
	showAction: true,
	actionStyle: {},
	actionText: '搜索',
	inputAlign: 'left',
	inputStyle: {},
	disabled: false,
	borderColor: 'transparent',
	searchIconColor: '#909399',
	searchIconSize: 22,
	color: '#606266',
	placeholderColor: '#909399',
	searchIcon: 'search',
	margin: '0',
	animation: false,
	maxlength: '-1',
	height: 32,
};
export default search;


