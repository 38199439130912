import api, { type ResponseData } from '../index'
import type { pageParam } from '../type'
// 注册
export const postRegister = (data: {
	phone: string,
	password: string,
	validatorCode: string,
	countryId: number
}) =>
	api.post('merchant/register', {
		data,
		custom: {
			auth: false,
			isLoading: true
		}
	})
// 注册短信
export const postRegisterSms = (phone: string, countryId: number) =>
	api.post(`merchant/register/sms/${countryId}/${phone}`,
		{
			custom: {
				auth: false
			}
		}
	)
// 忘记密码
export const postForgetPwd = (data: {
	phone: string,
	password: string,
	validatorCode: string
}) =>
	api.post('merchant/forget/password',
		{
			data,
			custom: {
				auth: false
			}
		}
	)
// 忘记密码短信
export const postForgetSms = (phone: string) =>
	api.post(`merchant/forget/password/sms/${phone}`,
		{
			custom: {
				auth: false
			}
		}
	)
// 登录
export const postLogin = (data: {
	username: string,
	password: string,
	model: string
	areaCode: string
	countryId: number | string
}) =>
	api.post('auth/merchant/login',
		{
			data,
			custom: {
				auth: false,
				isLoading: true
			}
		}
	)
// 绑定手机号
export const postBindPhone = data =>
	api.put('customer/api/bindPhone',
		{
			data,
			custom: {
				auth: false,
				isLoading: true
			}
		}
	)
export type PasswordForm = {
	username: string,
	password?: string,
	paywode?: string,
	validcode: string
}
// 修改登录密码
export const updatePassword = (data: PasswordForm) =>
	api.put('customer/api/updatePassword',
		{
			data,
			custom: {
				isLoading: true
			}
		}
	)
// 修改登录密码
export const updatePayword = (data: PasswordForm) =>
	api.put('customer/api/updatePayword',
		{
			data,
			custom: {
				isLoading: true
			}
		}
	)

// 退出登录
export const logout = () =>
	api.delete('auth/merchant/logout', {
		custom: {
			auth: false
		}
	})


export type NoticeItem = {
	id: number,
	noticeId: number,
	noticeTitle: string,
	noticeContent: string,
	createTime: string,
}
// 产品动态
export const getSystemHelpNotice = (data: pageParam) =>
	api.get<ResponseData<NoticeItem>>('admin/api/merchant/help/notice',
		{
			data
		}
	)
// 商家通知
export const getStoreNotice = (data: pageParam) =>
	api.get<ResponseData<NoticeItem>>('system/api/notice/2/list',
		{
			data
		}
	)
// 学习中心
export const getSystemHelpAgreement = (data: pageParam) =>
	api.get<ResponseData<NoticeItem>>('admin/api/merchant/help/agreement',
		{
			data
		}
	)

export type CountryItem = {
	countryArea: string,
	id: number
}
// 获取国家手机号列表
export const getPhonePrefix = () =>
	api.get<ResponseData<CountryItem>>('system/api/basics/phonePrefix', {
		custom: {
			auth: false,
			isLoading: true
		}
	})

// 协议
export const getAgreement = (data) =>
	api.get('system/merchant/basics/agreement',
		{
			data,
			custom: {
				auth: false
			}
		}
	)