export default {
	storeType1:'线上店铺',
	storeType2:'线下店铺',
	storeTypeTips1:'为商家提供解决网上开店问题',
	storeTypeTips2:'为商家提供线下店铺管理销售',
	storeState1:'审核中',
	storeState2:'不通过',
	storeState3:'营业中',
	storeState4:'已打烊',
	addStore:'创建店铺',
	delStoreTips:'确定要删除该店铺吗？',
	selectTypeTitle:'请选择需要入驻的店铺类型',
	storeStepTxt1:'经营信息',
	storeStepTxt2:'店铺信息',
	storeStepTxt3:'提交审核',
	next:'下一步',
	prev:'上一步',
	storeStepLabe1:'请选择你的主营类目',
	storeStepLabe2:'店铺名称',
	storeStepLabe3:'执照类型',
	storeStepLabe4:'营业执照',
	storeStepLabe5:'组织机构代码',
	storeStepLabe6:'营业执照地址',
	storeStepLabe7:'营业执照详细地址',
	storeStepLabe8:'店铺门头照',
	storeStepLabe9:'营业时间',
	storeStepLabe10:'法人姓名',
	storeStepLabe11:'法人身份证号码',
	storeStepLabe12:'法人身份证照片',
	storeStepLabe13:'负责人姓名',
	storeStepLabe14:'负责人电话',
	storeStepLabeTips1:'请选择类目',
	storeStepLabeTips2:'请输入店铺名称',
	storeStepLabeTips3:'请选择执照类型',
	storeStepLabeTips4:'请上传营业执照',
	storeStepLabeTips5:'请输入组织机构代码',
	storeStepLabeTips6:'请选择营业执照地址',
	storeStepLabeTips7:'请输入营业执照详细地址',
	storeStepLabeTips8:'请上传店铺门头照',
	storeStepLabeTips9:'请选择营业时间',
	storeStepLabeTips10:'请输入法人姓名',
	storeStepLabeTips11:'请输入法人身份证号码',
	storeStepLabeTips12:'请上传法人身份证照片',
	storeStepLabeTips13:'请输入店主姓名',
	storeStepLabeTips14:'请输入店主电话',
	storeStepLabeTips15:'请先阅读并同意《入驻服务协议》',
	imgSizeTips:'图片尺寸800*800以上，大小5mb以内，支持png,jpeg,gif格式',
	agreement:'《入驻服务协议》',
	submit:'提交资料',
	to:'至',
	logo:'店铺头像',
	name:'店铺名',
	state:'营业状态',
	selectAddress:'选择所在地区',
	select:'请选择',
	resubmit:'重新提交',
	applying:'店铺正在审核中，请耐心等候…',
	applicationError:'店铺申请失败，请重新提交申请资料',
	legalPrincipalIdcardImage:'法人证件照',
	edit:'编辑资料',
	save:'保存',
	startTime:'开始时间',
	endTime:'结束时间'
}