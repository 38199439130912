import { ref } from 'vue';
import { defineStore } from 'pinia'
import type { TimeCardItem,TimeCardServeItem } from '@/common/request/api'

interface StoreTimeCardItem extends Partial<TimeCardItem> { id:string,_myId : string,list:TimeCardServeItem[] }

interface CommissionConfigItem {
		id : number | string
		commissionScale : string
		commissionType : number
		serviceIds:string
		rules : {
			commissionScale : string,
			commissionType : number
			staffIds : string | number
		}[]
	}
interface CommissionConfig {
		id : string
		list:CommissionConfigItem[]
	}
export const useApplication = defineStore('application', () => {
	const timeCardForm = ref<StoreTimeCardItem>({
		id:'',
		headline: '',
		price: '',
		exchangeSize: '',
		isEffective: 1,
		effectiveTime: '',
		isRf: 0,
		limitDays: '',
		limitNumber: '',
		background: '#333333',
		backgroundUrl: '',
		colorType: '1',
		list:[],
		_myId: ''
	})
	const setTimeCardForm = (value:StoreTimeCardItem) => {
		timeCardForm.value = value
	}
	const commissionConfig = ref<CommissionConfig>({
		id:'',
		list:[]
	})
	const setCommissionConfig = (value:CommissionConfig) => {
		commissionConfig.value = value
	}
	return {
		timeCardForm,
		setTimeCardForm,
		commissionConfig,
		setCommissionConfig
	}
})