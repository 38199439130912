import { getIMUserId, dispatcher } from '.'


export type ReceiveMsg = {
	avatar:string//用户头像
	bc:string
	c:string//消息内容
	ct:number//1、普通文本消息，2、超链接卡片消息，3、文件，4、音频，5、视频 ，6、图片 ，9、名片，10、视频通话，11、音频通话，12、红包，88、链接消息，100、自定义消息
	ic:{
		coverurl:string
		url:string
	},
	mid:string
	msgtype:number //消息类型
	nick:string//用户昵称
	sendbysys:1|2//是否为系统消息 1:系统消息；2:非系统消息
	t:string//时间
	touid:number//接收方userid
	uid:number//发送方userid
}
let activeSessionKey = ''
uni.$on('sessionOper',(v:string) => {
	activeSessionKey = v
})

// #ifdef APP-PLUS
const platform = plus.os.name
const audioContext  = plus.audio.createPlayer({
    src: '/static/newMsg.mp3'
});
audioContext.setSessionCategory('ambient');
// #endif
// #ifndef APP-PLUS
const audioContext  = uni.createInnerAudioContext();
audioContext.src = '/static/newMsg.mp3';

// #endif


 const audioPlay = () => {
	// #ifdef APP-PLUS
	// android需判断系统音量才播放音频,ios下可直接播放
	if (platform == 'Android') {
	    let volume = plus.device.getVolume();
	    if (volume === 0) {
	        return;
	    }
	}
	
	// #endif
	console.log(audioContext)
	audioContext.play();
}

class msgStorage extends dispatcher {
	// 缓存新消息，不管是自己发送的还是接收到的，都视为新消息
	saveMsg(sendableMsg : ReceiveMsg) {
		let storeId = getIMUserId()
		let bodytouid=sendableMsg.touid,//接收方userid
		    bodyuid=sendableMsg.uid;//发送方userid
		const sessionKey = `${storeId}-${storeId === bodytouid ? bodyuid: bodytouid}`
		this.fire('newChatMsg', { curChatMsg:sendableMsg, sessionKey })
		uni.$emit('newChatMsg', { curChatMsg:sendableMsg, sessionKey })
		if(!activeSessionKey){
			// 当前没有正在聊天的会话
			audioPlay()
		}
		if(activeSessionKey !== sessionKey && storeId !== bodyuid){
			// 当前没有正在聊天的会话或收到的消息不是当前会话的，需更新未读消息数量
			uni.$emit('updateReadMessageNum', { curChatMsg:sendableMsg,bodyuid:`${bodyuid}` })
		}
		// return 
		// // 如果是自己发送的消息，则获取发送对象的聊天记录缓存key，反之获取接收对象的的聊天记录缓存key
		// let sessionKey = `newChatMsg_${storeId}${sendableMsg.body.from === storeId ? sendableMsg.body.to : sendableMsg.body.from}`
		// // 获取缓存的消息
		// let curChatMsg = uni.getStorageSync(sessionKey) || []
		// const renderableMsg = msgPackager(sendableMsg, type, storeId)
		// curChatMsg.push(renderableMsg)
		// uni.setStorage({
		// 	key: sessionKey,
		// 	data: curChatMsg,
		// 	success: () => {
		// 		// 缓存成功后发送新增新消息记录的广播
		// 		// 如果新消息是自己发送的或者是当前与自己正在聊天的用户发送过来的，那么会因为IM/detail页面中有监听newChatMsg的广播
		// 		// 会将缓存内的新消息缓存清除，同时加入到历史聊天记录的缓存中，这样才不会导致这类消息还会有未读数量的提示
		// 		// 注：每个聊天对象的未读数量都是根据这个聊天对象的newChatMsg缓存数据长度返回的
				
		// 	}
		// })
	}

}



export default new msgStorage