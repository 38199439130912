export type  FormItem = {
	label?: string,
	prop?: string,
	borderBottom?: boolean,
	labelPosition?:"left"|"top",
	labelWidth?: string|number,
	rightIcon?: string,
	leftIcon?: string,
	leftIconStyle?:string|Record<string,any>
	required?: boolean,
	requiredPosition?:"left"|"right",
	customStyle?: Record<string,any> |string,
	customClass?:string
}
export type FormItemPropsType = Omit<
	FormItem,
	"labelPosition"|"requiredPosition"
> & {
	labelPosition?: string;
	requiredPosition?:string
};
export default {
    // formItem 组件
    required: false,
}
