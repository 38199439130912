import api , { type ResponseData }from '../index'
import type { Locale } from '@/common/init/i18n'
// 图片域名
export const getImageDomain = () =>
	api.get<{
		code:number,
		siteCurrency:string,
		siteClient:Locale,
		data:string,
		applicationAll:Array<{configKey:string,configName:string}>
	}>('system/basics/imagePrefix', {
		custom: {
			auth: false
		}
	})
