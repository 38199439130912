import {AlignTypes,ShapeTypes} from '../props'

export type InputCommonProps = {
		modelValue?: string | number,
		placeholder?: string | number,
		placeholderClass?: string,
		placeholderStyle?: string | Record<string, any>,
		height?: string | number,
		confirmType?: "send"| "search"| "next"| "go"|"done",
		disabled?: boolean,
		count?: boolean,
		focus?: boolean,
		autoHeight?: boolean,
		fixed?: boolean,
		cursor?: string | number,
		cursorSpacing?: string | number,
		showConfirmBar?: boolean,
		selectionStart?: number,
		selectionEnd?: number,
		adjustPosition?: boolean,
		disableDefaultPadding?: boolean,
		holdKeyboard?: boolean,
		maxlength?: string | number,
		border?: 'surround' | 'bottom' | 'none',
		formatter?: () => string,
		customStyle?: Record<string,any>|string,
		customClass?:string
}

export type Input = {
	type?: "text" | "number" | "idcard" | "digit" | "password",
	disabledColor?: string,
	clearable?: boolean,
	password?: boolean,
	showWordLimit?: boolean,
	confirmHold?: boolean,
	autoBlur?: boolean,
	inputAlign?: AlignTypes,
	fontSize?: '15px',
	color?: string,
	prefixIcon?: string,
	prefixIconStyle?: string | Record<string, any>,
	suffixIcon?: string,
	suffixIconStyle?: string | Record<string, any>,
	readonly?: boolean,
	shape?: ShapeTypes,
	passwordIconShow?: boolean,

}&InputCommonProps;
export type InputPropsType = Omit<Input, 'border'|'confirmType'|'inputAlign'|'shape'|'type'> & {
	type?: string
	confirmType?:string
	inputAlign?:string
	shape?:string
	border?: string
}
const input: Input = {
	// input组件
	modelValue: '',
	type: 'text',
	fixed: false,
	disabled: false,
	disabledColor: '#f5f7fa',
	clearable: false,
	password: false,
	maxlength: -1,
	placeholder: null,
	placeholderClass: 'input-placeholder',
	placeholderStyle: 'color: #c0c4cc',
	showWordLimit: false,
	confirmType: 'done',
	confirmHold: false,
	holdKeyboard: false,
	focus: false,
	autoBlur: false,
	disableDefaultPadding: false,
	cursor: -1,
	cursorSpacing: 30,
	selectionStart: -1,
	selectionEnd: -1,
	adjustPosition: true,
	fontSize: '15px',
	color: '#303133',
	prefixIcon: '',
	prefixIconStyle: '',
	suffixIcon: '',
	suffixIconStyle: '',
	border: 'surround',
	readonly: false,
	shape: 'square',
	formatter: null
};
export default input;
