/*异常通知*/
const appcode = {
	NOTLOGIN: 1001, //没有登录
	TIMEOUT: 1002, //登录超时
	KICKTED: 1003, // 帐号在其它地方登录
	NOTPERMISSION: 1004, //登录了，但是没有权限操作
	REFUSE: 1005, //拒绝访问
	NEED_ACCESS_TOKEN: 1006, //需要提供正确的access_token
	CAPTCHA_ERROR: 1007, // 图形验证码错误code
	KICKTED_H5: 1010,
	NO_GRANT: 1099 // 未授权
}
const WxFriendErrorNtf = function (bodyObj)  {

	let errorcode = bodyObj.code
	switch (errorcode) {
		case appcode.NOTLOGIN:
		case appcode.TIMEOUT:
		case appcode.KICKTED_PC:
			//断开心跳连接
			// tiows.stop();
			// location.reload();
			break;

	}
};
export default WxFriendErrorNtf;