export default {
		list: '次卡列表',
		order:'次卡订单',
		cardEmpty:'暂无次卡',
		addCard:'添加次卡',
		down:'已下架',
		up:'已上架',
		cardSearchTips:'请输入次卡名称搜索',
		orderSearchTips:'请输入名称/订单编号搜索',
		card:'次卡',
		effective:'前有效',
		permanent:'永久有效',
		selectState:'选择状态',
		add:'+去添加',
		orderStatus1:'已失效',
		orderStatus2:'已使用',
		orderStatus3:'待使用',
		orderSubStatus1:'买家未在规定时间内使用，订单已失效',
		orderSubStatus2:'付款完成，待买家使用',
		limitTxt:'-DAY-天可兑换-NUMBER-次',
		noLimit:'不限兑换次数',
		cardEdit:'次卡编辑',
		cardDown:'次卡下架',
		cardUp:'次卡上架',
		cardHistory:'售卡记录',
		cardSales:'已售卡-NUMBER-张',
		cardDownTips:'停售后，用户无法购买，确认下架吗？',
		cardUpTips:'上架后，用户能购买，确认上架吗？',
		cardNameTips:'请输入服务名称',
		cardNamePlaceholder:'请输入服务名称，最多20个字',
		cardPrice:'次卡价格',
		cardPriceTips:'请输入次卡价格',
		cardNumber:'次数',
		cardNumberTips:'请输入次数',
		cardLabel1:'默认背景色',
		cardLabel2:'自定义背景',
		cardLabel2Tips:'请上传背景图片',
		cardImgTips:'建议尺寸750X390 小于3M',
		cardLabel3:'卡有效期',
		cardLabel3Tips:'请输入有效期',
		cardLabel4:'永久有效',
		cardLabel5:'兑换频率',
		cardLabel5Tips:'请输入兑换频率',
		cardLabel6:'不限兑换次数',
		cardLabel7:'每-DAY-天，当前次卡可使用',
		addServe:'添加服务',
		serveEmpty:'暂无服务',
		addSku:'添加规格',
		allSku:'全部规格',
		selectSkuTips:'请选择规格',
		selectSkuError:'规格已存在',
		cardSaveTips:'请先添加服务',
		cardSaveSuccess1:'次卡创建成功',
		cardSaveSuccess2:'次卡编辑成功',
		cardInfo:'次卡信息',
		cardHistoryTitle:'售卡'
		
		
	
}