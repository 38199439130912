import saasIM from '@/common/utils/IM/IM'
import msgStorage, { type ReceiveMsg } from '@/common/utils/IM/msgStorage'
import { empty,AppCookie } from '@/common/utils'
import { useUser,useIM } from "@/common/stores";
import { setRequestHeader } from '@/common/request'
import { getIMUrlConfig } from '@/common/request/api'
import type { TiocomConfig } from '@/common/request/api'


let isInitUrl = false

let initCb : Function[] = []

const tokenName = 'tio_access_token'
const getWSQueryString = (sessionName : string) => {
	let token = AppCookie.get(tokenName)
	return `wx=1&${sessionName}=${encodeURIComponent(AppCookie.get(sessionName)||'')}&frompath=/home&tenant=${uni.getStorageSync("tenant")}${token ? `&${tokenName}=${encodeURIComponent(token)}` :'' }`;
}

export const setIMUrl = async (config : TiocomConfig) => {
	let url = ''
	try {
		const res = await getIMUrlConfig()
		url = `${res.data.ssl == 1 ? 'wss' : 'ws'}://${res.data.ip}:${res.data.port}`
		saasIM.config.heartbeatTimeout = Number(res.data.timeout)
	} catch (e) {
	
	}
	
	saasIM.config.url = `${url}?${getWSQueryString(config.session_cookie_name)}`
	saasIM.config.cookieName = config.session_cookie_name
	
	isInitUrl = true
	if (initCb.length) {
		initCb.forEach(item => item?.())
	}

}

let quitTime = 0
export const IMInit = () => {
	if (isInitUrl) {
		saasIM.init({
			onOpened() {
				// IM登录成功回调
				uni.hideLoading();
			},
			onOutLogin () {
				AppCookie.remove(saasIM.config.cookieName)
			},
			onQuit() {
				console.log('onQuit')
				// IM鉴权失效，退出IM登录回调
				AppCookie.remove(saasIM.config.cookieName)
				const userStore = useUser();
				// 距离上次退出登录时间间隔不到一分钟，认为是登录异常，用户需重新登录
				if(quitTime&& new Date().getTime() - quitTime < 60000){
					uni.reLaunch({
						url: "/pages/public/account/appLogin",
					});
					userStore.outLogin();
					setRequestHeader()
				}else{
					const IMStore = useIM()
					IMStore.IMLogin(userStore.storeDetail)
					quitTime = new Date().getTime()
				}
			},
			onError(err) {
				console.log(err.type, err.error)
				uni.hideLoading();
			}
		})
	} else {
		initCb[0] = IMInit
	}

}

export const IMLogin = () => {
	if (isInitUrl) {
		uni.showLoading({
			title: "loading...",
			mask: true
		});
		saasIM.open()
	} else {
		initCb[1] = IMLogin
	}

}