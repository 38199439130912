export type DropdownMenu = {
	activeColor?: string
	inactiveColor?: string
	duration?: number
	overlay?: boolean
	closeOnClickOverlay?: boolean
	upIconName?:string
	downIconName?:string
	iconSize?:string|number
	height?:string|number
	popupZIndex?:number
	bgColor?: string
	itemStyle?:string|Record<string,any>,
	scrollable?:boolean
	props?:{
		label:string,
		value:string
	}
	borderBottom?:boolean
	borderColor?:string
	disabled?:boolean
};
export type DropdownMenuPropsType = DropdownMenu;
const dropdownMenu: DropdownMenu = {
	// dropdownMenu 组件
	activeColor: "#2979ff",
	inactiveColor:'#333',
	duration: 300,
	overlay: true,
	closeOnClickOverlay: true,
	upIconName:'arrow-up-fill',
	downIconName:'arrow-down-fill',
	iconSize:10,
	height:44,
	popupZIndex:10075,
	bgColor:'#fff',
	scrollable: false,
	props:{
		label:'label',
		value:'value'
	},
	borderBottom:false,
	borderColor:'#d6d7d9',
	disabled:false
};
export default dropdownMenu;
