export default {
	stateTxt1:'出售中',
	stateTxt2:'已售罄',
	stateTxt3:'仓库中',
	stateTxt4:'审核中',
	stateTxt5:'不通过',
	searchTips:'请输入商品名称搜索',
	empty:'暂无商品',
	sales:'销量：',
	stock:'库存：',
	addGoods:'新增商品',
	down:'下架',
	up:'上架',
	batch:'批量管理',
	checkAll:'全选',
	del:'删除',
	delTips:'确定要删除选中的商品吗？',
	goodsType1:'实物商品',
	goodsTypeTxt1:'（物流发货）',
	goodsType2:'虚拟商品',
	goodsTypeTxt2:'（无需物流）',
	goodsLabe1:'标题',
	goodsLabe2:'商品类目',
	goodsLabe3:'分享描述',
	goodsLabe4:'封面图',
	goodsLabe5:'封面视频',
	goodsLabe6:'商品规格/价格',
	goodsLabe7:'划线价格',
	goodsLabe8:'商品编码',
	goodsLabe9:'商品条码',
	goodsLabe10:'快递运费',
	goodsLabe11:'开售时间',
	goodsLabe12:'商品详情',
	goodsLabe13:'商品权益',
	goodsLabe14:'虚拟销量',
	goodsLabe15:'发货时间',
	goodsLabe16:'起售件数',
	goodsLabeTips1:'请输入最多32字符的标题',
	goodsLabeTips2:'请选择商品类目',
	goodsLabeTips3:'请输入最多36字符的标题',
	goodsLabeTips4:'请上传封面图',
	goodsLabeTips5:'商品规格不能为空！',
	goodsLabeTips6:'请输入划线价格',
	goodsLabeTips7:'请输入商品编码',
	goodsLabeTips8:'请输入商品条码',
	goodsLabeTips9:'请填写运费',
	goodsLabeTips10:'请选择运费模板',
	goodsLabeTips11:'请选择开售日期',
	goodsLabeTips12:'请输入虚拟销量',
	goodsLabeTips13:'请选择发货日期',
	goodsLabeTips14:'请填写发货日期',
	imgTips:'为显示效果，封面图建议800*800，最多上传15张',
	videoTips:'添加主图视频可提升商品的成交转化，有利于获取更多流量，建议时长 9-30 秒、视频宽高和商品主图一致。',
	img:'图片',
	video:'视频',
	freightTxt1:'统一运费',
	freightTxt2:'运费模板',
	timeTxt1:'立即开售',
	timeTxt2:'定时开售',
	timeTxt3:'开始发货',
	timeTxt4:'尾款支付',
	timeTxt5:'天后发货',
	timeTips1:'请选择日期',
	timeTips2:'只允许设置90天内的发货时间，请务必按照约定时间发货以免引起客户投诉',
	rightsInterestsTips:'当商品权益不选择的时候，用户端则不显示',
	virtualTips:'用于商品详情页面的销售数量显示，不影响实际销售及数据',
	classlfySearch:'请输入商品类目搜索',
	emptySku:'暂无规格',
	addSku:'新增规格',
	addSkuTips:'请完善规格信息，再进入下一步',
	confirm:'继续完善',
	cancel:'退出',
	skuName:'规格名',
	addSkuValue:'添加规格值',
	skuValue:'规格值',
	added:'已添加',
	sku:'规格：',
	price:'价格',
	pricePlaceholder:'请输入价格',
	weight:'重量',
	weightPlaceholder:'请输入重量',
	costPrice:'成本价',
	costPlaceholder:'请输入成本价',
	stockPlaceholder:'请输入库存',
	saveSkuTips:'请先完善规格明细',
	release:'发布',
	reAudit:'重新审核',
	skuTips:'请先完善商品规格/价格',
	placeholder:'请输入详情内容',
	shelvesWarning:'所选商品包含未审核商品，是否继续？',
	shelvesError:'所选商品未审核，无法上架'
}