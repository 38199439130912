export default {
  storeType1: "オンラインストア",
  storeType2: "オフラインストア",
  storeTypeTips1: "オンラインストアを開設するためのソリューションを提供します",
  storeTypeTips2: "オフラインストアを管理して販売します",
  storeState1: "審査中",
  storeState2: "不合格",
  storeState3: "営業中",
  storeState4: "閉店済み",
  addStore: "ストアを作成",
  delStoreTips: "このストアを削除してもよろしいですか？",
  selectTypeTitle: "入居するストアタイプを選択してください",
  storeStepTxt1: "経営情報",
  storeStepTxt2: "ストア情報",
  storeStepTxt3: "審査を提出",
  next: "次へ",
  prev: "前へ",
  storeStepLabe1: "主要なカテゴリを選択してください",
  storeStepLabe2: "ストア名",
  storeStepLabe3: "ライセンスタイプ",
  storeStepLabe4: "事業ライセンス",
  storeStepLabe5: "組織コード",
  storeStepLabe6: "事業ライセンス住所",
  storeStepLabe7: "事業ライセンス詳細住所",
  storeStepLabe8: "ストア表札写真",
  storeStepLabe9: "営業時間",
  storeStepLabe10: "法人名",
  storeStepLabe11: "法人身分証番号",
  storeStepLabe12: "法人身分証明書写真",
  storeStepLabe13: "責任者名",
  storeStepLabe14: "責任者電話番号",
  storeStepLabeTips1: "カテゴリを選択してください",
  storeStepLabeTips2: "ストア名を入力してください",
  storeStepLabeTips3: "ライセンスタイプを選択してください",
  storeStepLabeTips4: "事業ライセンスをアップロードしてください",
  storeStepLabeTips5: "組織コードを入力してください",
  storeStepLabeTips6: "事業ライセンスの住所を選択してください",
  storeStepLabeTips7: "事業ライセンスの詳細住所を入力してください",
  storeStepLabeTips8: "ストア表札写真をアップロードしてください",
  storeStepLabeTips9: "営業時間を選択してください",
  storeStepLabeTips10: "法人名を入力してください",
  storeStepLabeTips11: "法人身分証番号を入力してください",
  storeStepLabeTips12: "法人身分証明書写真をアップロードしてください",
  storeStepLabeTips13: "店主名を入力してください",
  storeStepLabeTips14: "責任者の電話番号を入力してください",
  storeStepLabeTips15: "「テナント入居契約書」に同意してください",
  imgSizeTips:
    "画像サイズは800×800ピクセル以上、ファイルサイズは5MB以内で、png、jpeg、gifフォーマットに対応しています",
  agreement: "「テナント契約書」",
  submit: "情報を提出する",
  to: "まで",
  logo: "ストアのロゴ",
  name: "ストア名",
  state: "営業状態",
  selectAddress: "所在地を選択してください",
  select: "選択してください",
  resubmit: "再提出する",
  applying: "ストアは現在審査中です。しばらくお待ちください...",
  applicationError:
    "ストア申請に失敗しました。再度申請情報を提出してください。",
  legalPrincipalIdcardImage: "法人の身分証明書写真",
  edit: "情報を編集する",
  save: "保存する",
  startTime: "開始時間",
  endTime: "終了時間",
};
