export default {
	iconTxt1:'今日营业额',
	iconTxt2:'总营业额',
	iconTxt3:'退款金额',
	iconTxt4:'待付款',
	iconTxt5:'待发货',
	iconTxt6:'待收货',
	iconTxt7:'待评价',
	iconTxt8:'退款/售后',
	iconTxt9:'商品管理',
	iconTxt10:'营销玩法',
	iconTxt11:'交易服务',
	iconTxt12:'发布商品',
	iconTxt13:'商品管理',
	iconTxt14:'扫码核销',
	iconTxt15:'订单管理',
	iconTxt16:'评价管理',
	iconTxt17:'核销记录',
	businessOpen:'营业中',
	businessClose:'已打烊'
	
}