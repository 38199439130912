import zIndex from './zIndex'
export type Skeleton = {
	show?: boolean
	zIndex?:string | number
	config?:{
		list?:{
			length:number
			showAvatar:boolean
			avatarShape:'circle'|'square'
		}
		menu?:{
			rows:number
			cols:number
			itemShape: 'circle' | 'square',
			showText:boolean
		}
		navbar?:{
			fixed : boolean
			zIndex:number
			placeholder : boolean
			height : number
			showLeft:boolean
			showRight:boolean
		}
		swiper?:{
			dot:number
		}
		tabbar?:{
			fixed:boolean
			placeholder:boolean
			zIndex:number
			bulgeIndex?:number
			length:number
			showText:boolean
			itemShape: 'circle' | 'square',
		}
	}
	safeAreaInsetBottom?:boolean
	safeAreaInsetTop?:boolean
	overlayOpacity?: string | number
	customStyle?: Record<string,any>|string
};
export type SkeletonPropsType = Omit<Skeleton,'config'>&{
	config?:Record<string,any>
} 
const skeleton: Skeleton = {
	// skeleton组件
	show: false,
	zIndex:zIndex.skeleton,
	config:{
		list:{
			length:5,
			showAvatar:true,
			avatarShape:'square'
		},
		menu:{
			rows:2,
			cols:4,
			itemShape: 'circle',
			showText:true
		},
		navbar:{
			fixed: true,
			zIndex:zIndex.skeletonNavbar,
			placeholder: true,
			height: 44,
			showLeft:true,
			showRight:false
		},
		swiper:{
			dot:3
		},
		tabbar:{
			fixed:true,
			placeholder:true,
			zIndex:965,
			length:5,
			showText:true,
			itemShape: 'circle',
		}
	},
	safeAreaInsetBottom:true,
	safeAreaInsetTop:false
};
export default skeleton;
