import { FormRules } from "../../tokens/form";
import {AlignTypes} from '../props'
export type  Form = {
	model?: Record<string,any> ,
	rules?: FormRules,
	errorType?: "none"|"toast",
	borderBottom?: boolean,
	labelWidth?: string|number,
	labelPosition?: "left"|"top",
	labelAlign?: "left"|"center "|"right",
	labelStyle?:Record<string,any>,
	requiredPosition?:"left"|"right",
	inputAlign?:AlignTypes,
}
export type FormPropsType = Omit<
	Form,
	"errorType"|"labelPosition"|"labelAlign"|"requiredPosition"|"inputAlign"
> & {
	errorType?:string,
	labelPosition?: string,
	labelAlign?:string,
	requiredPosition?:string
	inputAlign?:string
};

const form:Form = {
	// form 组件
	errorType: "none",
	borderBottom: true,
	labelPosition: "left",
	labelWidth: 45,
	labelAlign: "left",
	rules:{},
	inputAlign: "left",
}
export default form
