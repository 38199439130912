export default {
  viewAll: "すべて表示",
  empty: "履歴がありません",
  noCard: "現在、銀行カードが登録されていません",
  noBinding: "登録された銀行カードがありません",
  binding: "+バインディング",
  addCard: "新しい銀行カードを登録",
  isDelCard: "この銀行カードの登録を解除してもよろしいですか？",
  cardLabel1: "カードホルダー",
  cardLabel2: "身分証明書番号",
  cardLabel3: "開戸行",
  cardLabel4: "カード番号",
  cardLabel5: "携帯電話番号",
  cardTips1: "カードホルダーの名前を入力してください",
  cardTips2: "身分証明書番号を入力してください",
  cardTips3: "開戸行を選択してください",
  cardTips4: "銀行カード番号を入力してください",
  cardTips5: "銀行に登録されている携帯電話番号を入力してください",
  nowBinding: "今すぐ登録",
  selectCard: "受取銀行カードを選択",
  extractCard: "受取銀行カード",
  nowExtract: "今すぐ引き出す",
  allExtract: "全部引き出す",
  userMoney: "引き出し可能残高",
  extractMoney: "引き出し金額",
  extractFee: "引き出し手数料",
  extractTips1: "銀行カードを選択してください",
  extractTips2: "引き出し金額を入力してください",
  extractTips3: "最低引き出し金額は",
  extractTips4: "最大引き出し金額は",
  extractSuccessTips1: "引き出し成功",
  extractSuccessTips2: "引き出し金額は1〜3営業日以内にアカウントに反映されます",
  extractStatus1: "審査中",
  extractStatus2: "引き出し成功",
  extractStatus3: "審査失敗",
  income: "収入",
  expend: "支出",
  balanceDetails: "残高詳細",
  frozenDetails: "凍結詳細",
};
