export default {
  confirm: "確認",
  completed: "完了",
  back: "戻る",
  year: "年",
  month: "月",
  day: "日",
  tips: "ヒント",
  payPawTips: "支払いパスワードを入力してください",
  forgotPaw: "パスワードを忘れましたか？",
  all: "すべて",
  del: "削除",
  cancel: "キャンセル",
  determine: "決定",
  placeholder: "入力してください",
  edit: "編集",
  save: "保存",
  copy: "コピー",
  copySuccess: "クリップボードにコピーしました",
  submit: "送信",
  common: "共",
  piece: "個",
  createTime: "作成時間",
  number: "数量",
};
