import zIndex from './zIndex'
export type Select = {
	modelValue ?: string | number | boolean
	disabled ?: boolean
	disabledColor ?: string
	placeholder ?: string
	placeholderClass ?: string
	placeholderStyle ?: string | Record<string, any>
	border ?: "surround" | "bottom" | "none"
	shape ?: "square" | "circle"
	showOptionNumber?:number
	zIndex ?: string | number

};
export type SelectPropsType = Omit<
	Select,
	"shape" | "border"
> & {
	shape ?: string;
	border ?: string
};
const select : Select = {
	// select组件
	disabled: false,
	disabledColor: "#f5f7fa",
	placeholderClass: "input-placeholder",
	placeholderStyle: "color: #c0c4cc",
	border: "surround",
	shape: "square",
	showOptionNumber:3,
	zIndex: zIndex.select,
};
export default select;