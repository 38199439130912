import common from './list/common'
import Public from './list/public'
import home from './list/home'
import order from './list/order'
import user from './list/user'
import store from './list/store'
import tabbar from './list/tabbar'
import application from './list/application'
import goods from './list/goods'
import endlessZh from '@/uni_modules/endless-ui/libs/locale/zh-Hans'

export default {
	common,
	public:Public,
	home,
	order,
	user,
	store,
	tabbar,
	application,
	goods,
	...endlessZh
};