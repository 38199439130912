import type {ImageModeTypes} from './types'
export type Album = {
	urls?: Array<string>|Array<Record<string,any>>
	keyName?: string
	singleSize?: number
	multipleSize?: number
	space?: number
	singleMode?: ImageModeTypes
	multipleMode?: ImageModeTypes
	maxCount?: number
	previewFullImage?: boolean,
	rowCount?:number
	showMore?: boolean
};
export type AlbumPropsType = Omit<
	Album,
	"singleMode"|"multipleMode"
> & {
	urls?:Array<any>
	singleMode?: string;
	multipleMode?:string
};
const album: Album = {
	// album组件
	urls:[],
	singleSize: 180,
	multipleSize: 70,
	space: 6,
	singleMode: 'scaleToFill',
	multipleMode: 'aspectFill',
	maxCount: 9,
	previewFullImage: true,
	rowCount: 3,
	showMore: true

};
export default album;
