export const getCookie = (cookies : string[]) => {
	return cookies.map(item => {
		return item.split(';')[0]
	}).join('; ')
}

class Cookie {
	cks : Record<string, string> = {}
	init() {
		let initCookies:string[]
		// #ifdef H5
		initCookies = document.cookie ? document.cookie.split("; ") : [];
		// #endif
		// #ifndef H5
		initCookies = uni.getStorageSync('cookies')?.split("; ")||[]
		// #endif
		initCookies.forEach(item => {
			const indexOfEqual = item.indexOf("=");
			if (indexOfEqual !== -1) {
				const name = item.substr(0, indexOfEqual);
				const value = item.substring(indexOfEqual + 1);

				this.cks[name] = value
			}
		})
	}
	get(name : string,hasSuffix?:boolean) {
		let value = this.cks[name]
		if(value){
			return hasSuffix ? value: value.split(';')[0]
		}
		return null
	}
	getAll () {
		return Object.keys(this.cks).map(key => `${key}=${this.cks[key]}`).join('; ');
	}
	private setCookie () {
		let cookies = Object.keys(this.cks).map(key => `${key}=${this.cks[key]}`).join('; ');
		// #ifdef H5
		if(cookies){
			document.cookie = cookies
		}
		// #endif
		// #ifndef H5
		uni.setStorageSync('cookies',cookies)
		// #endif
	}
	set(name : string, value : string, attr ?: { expires ?: number, path ?: boolean }) {
		let nv =  encodeURIComponent(value); // + ';domain=' + rootDomain + ';max-age=' + 0 + ';path=/'
		let hasPath = false;
		if (attr) {
			if (attr.expires) {
				const expires = new Date();
				expires.setMilliseconds(expires.getMilliseconds() + attr.expires * 864e5);
				let v = expires.toUTCString();
				nv += ";expires=" + v;
			}
			if (attr.path) {
				hasPath = true;
			}
		}
		if (!hasPath) {
			nv += ";path=/"; // + v
		}
		this.cks[name] = nv
		this.setCookie()
		
	}
	remove(name : string) {
		let value = this.cks[name]
		if(value){
			delete this.cks[name]
			// #ifndef H5
			this.setCookie()
			// #endif
			// #ifdef H5
			this.set(name,'',{
				expires:-1
			})
			this.init()
			// #endif
			
		}
		
	}
}
const AppCookie = new Cookie()

export {
	AppCookie
} 