export default {
  empty: "クーポンがありません",
  type1: "￥",
  type2: "無条件",
  sub: "割引",
  state1: "未開始",
  state2: "有効期間中",
  state3: "期限切れ",
  timeState1: "クーポンの利用期限：開始日から",
  timeState2: "まで",
  txt1: "残り：",
  txt2: "受け取り済：",
  txt3: "割引",
  txt4: "割引",
  txt5: "最大割引額",
  invalidTips: "このクーポンを無効にしますか？",
  editTitle: "クーポン編集",
  addTitle: "クーポン追加",
  label1: "クーポンの種類",
  label2: "クーポン名",
  label3: "適用商品",
  label4: "利用条件",
  label5: "注文金額",
  label6: "で",
  label7: "割引",
  label8: "配布枚数",
  label9: "枚",
  label10: "受取期間",
  label11: "利用期間",
  label12: "使用回数制限",
  label13: "回",
  label14: "割引率",
  label15: "受取制限",
  label16: "すべての会員",
  label17: "指定会員",
  label18: "金額割引クーポン",
  label19: "割引率クーポン",
  label20: "編集済み",
  label21: "すべての商品",
  label22: "指定カテゴリ",
  label23: "指定商品",
  formTips1: "最大12文字のタイトルを入力してください",
  formTips2: "適用商品を選択してください",
  formTips3: "利用条件を入力してください",
  formTips4: "金額割引を入力してください",
  formTips5: "クーポン配布数を入力してください",
  formTips6: "受取期間を選択してください",
  formTips7: "利用期間を選択してください",
  formTips8: "使用回数制限を入力してください",
  // formTips9: '使用回数制限を入力してください',
  // formTips10: '割引率を入力してください',
  // formTips11: '受取制限を選択してください',
  release: "クーポンを追加する",
  addClassify: "カテゴリを追加",
  addGoods: "商品を追加",
  select: "カテゴリを選択",
  selectTips: "選択してください",
  userSearchTips: "ユーザーIDを入力して検索してください",
  userEmpty: "クーポンを受け取った人はいません",
  dataSearchTips: "注文番号/ユーザーIDを入力して検索してください",
  dataEmpty: "クーポンを使用した人はいません",
};
