
export type List = {
	showScrollbar?: boolean,
	renderReverse?: boolean,
	lowerThreshold?: string|number,
	upperThreshold?: string|number,
	scrollTop?: string|number,
	offsetAccuracy?: string|number,
	enableFlex?: boolean,
	pagingEnabled?: boolean,
	scrollable?: boolean,
	scrollIntoView?: string,
	scrollWithAnimation?: boolean,
	enableBackToTop?: boolean,
	height?: string|number,
	width?: string|number,
	preLoadScreen?: string|number
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type ListPropsType = List;
const list: List = {
	// list组件
	showScrollbar: false,
	renderReverse: false,
	lowerThreshold: 50,
	upperThreshold: 0,
	scrollTop: 0,
	offsetAccuracy: 10,
	enableFlex: false,
	pagingEnabled: false,
	scrollable: true,
	scrollIntoView: '',
	scrollWithAnimation: false,
	enableBackToTop: false,
	height: 0,
	width: 0,
	preLoadScreen: 1
};
export default list;
