
export type SkeletonItem = {
	variant?:'list'|'swiper'|'tabbar'|'navbar'|'menu'|'waterfall'
};
export type SkeletonItemPropsType = Omit<SkeletonItem,'variant'>&{
	variant?:string
}
const skeletonItem: SkeletonItem = {
	// skeletonItem组件
};
export default skeletonItem;
