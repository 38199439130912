import { Request } from "@/uni_modules/endless-ui/utils";
import { toastApp } from "@/common/utils/toast";
import { useUser } from "@/common/stores";
import { REQUEST_BASE_URL,LOCALE,REQUEST_SUCCESS_CODE } from '@/env'
import {empty,isObj,isArray} from '@/common/utils'
import { AppCookie } from '@/common/utils'
import type { ResponseData } from "./type";
let user:ReturnType<typeof useUser>;
const setStores = () => {
	if (!user) {
		user = useUser();
	}
};
const request = new Request<ResponseData&{ok?:boolean},{auth?: boolean,isLocation?: boolean,isLoading?: boolean}>({
	baseURL: REQUEST_BASE_URL,
	modifyData: (request: Promise<any>) =>
		new Promise((resolve, reject) => {
			request.then((res) => {
				if (res.statusCode === 200) {
					if (res.data && res.data.code === REQUEST_SUCCESS_CODE || res.data.ok) {
						resolve(res.data)
					}
					reject(res)
				}else{
					reject(res)
				}
			}).catch(reject);
		})
});

const getRequestConfigHeader = () => {
	let header: Record<string, string> = {
		lang: uni.getStorageSync('locale') || LOCALE
	};
	let Authorization = uni.getStorageSync("token");
	let tenant = uni.getStorageSync("tenant");
	if (Authorization) {
		header["Authorization"] = Authorization;
	}
	if (tenant) {
		header["tenant"] = tenant;
	}
	
	return header
}

// 设置请求头的token等字段，只需在初始化或有改动时设置即可，无需在拦截器中每次都去判断，设置请求头，减少不需要的操作
const setRequestHeader = () => {
	
	request.setConfig((config) => {
		let header = getRequestConfigHeader();
		header["Content-Type"] = "application/json"
		config.header = header
		return config;
	});
};

const deleteNullValue = (data?:Record<string,any>|string) => {
  if (!isObj(data)) return {}
  Object.keys(data).forEach(key => {
    if (empty(data[key])) {
      delete data[key]
    }
  })
  return data
}

request.interceptors.request.use((config) => {
	// #ifdef APP-NVUE
	// NVUE下request的全局变量是无法共享的，会导致config的header为空，需重新设置
	config.header = getRequestConfigHeader();
	// #endif
	// #ifndef H5
	config.header.Cookie = AppCookie.getAll() // 将每次的请求头中加入Cookies
	// #endif
	/* 请求之前拦截器 */
	// 不传auth或auth不为false，则该请求视为需要登录后请求
	if (config.custom?.auth !== false) {
		let token = uni.getStorageSync("token");
		// 所有需要登录的请求都不能在app初始化前调用，否则会因为pinia没有初始化而报错
		setStores();
		if (!token) {
			user.setLogin(false);
			uni.reLaunch({
				url: "/pages/public/account/appLogin",
			});
			console.error("暂未登录,已阻止此次API请求~" + "接口：" + config.url);
			return Promise.reject(config);
		}
	}
	if (config.custom?.isLoading) {
		uni.showLoading({
			title: "loading...",
			mask:true
		});
	}
	if(!empty(config.data)){
		if(isArray(config.data)){
			config.data = JSON.stringify(config.data)
		}else{
			config.data = deleteNullValue(config.data)
		}
	}
	return config;
});
request.interceptors.response.use((response) => {
	if (response.config.custom?.isLoading) {
		uni.hideLoading();
	}
	response.cookies?.forEach(item => {
		const arr = item.split(';')[0].split('=')
		AppCookie.set(arr[0],arr[1])
	})
	// console.log(response)
	/* 请求之后拦截器 */
	if (response.data.code === 500|| !empty(response.data.ok) && !response.data.ok) {
		// 服务端返回的状态码不等于200，则reject()
		toastApp(response.data.msg);
		return Promise.reject(response);
	}
	if (response.data.code === 401 || response.data.code === 403) {
		// 服务端返回的状态码不等于200，则reject()
		user.outLogin();
		setRequestHeader()
		return Promise.reject(response);
	}
	return response;
});


export {
	ResponseData,
	setRequestHeader
}
export default request;
