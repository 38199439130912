export default {
	confirm:'確認',
	completed:'完成',
	back:'返回',
	year:'年',
	month:'月',
	day:'日',
	tips:'提示',
	payPawTips:'請輸入支付密碼',
	forgotPaw:'忘記密碼？',
	all:'全部',
	del:'删除',
	cancel:'取消',
	determine:'確定',
	placeholder:'請輸入',
	edit:'編輯',
	save:'保存',
	copy:'複製',
	copySuccess:'已複製到粘貼板',
	submit:'提交'
}