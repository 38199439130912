interface SwipeActionItemOption {
	text?:string
	style?:Record<string,string>
	icon?:string
	iconSize?:string|number
}
export type SwipeActionItem = {
	show?: boolean,
	name?: string,
	disabled?: boolean,
	threshold?: number,
	autoClose?: boolean,
	options?: SwipeActionItemOption[],
	duration?: number
};
export type SwipeActionItemPropsType = SwipeActionItem
const swipeActionItem: SwipeActionItem = {
	show: false,
	disabled: false,
	threshold: 20,
	autoClose: true,
	options: [],
	duration: 300
};
export default swipeActionItem;
