import api from '../index'
import type { ResponseData, IMResponseData } from '../type'
export interface TiocomConfig {
	session_cookie_name:string//cookie键值
	res_server:string//资源地址
}
// 获取服务器的IM配置
export const getIMConfig = () =>
	api.get<IMResponseData<TiocomConfig>>('mytio/config/viewmodel.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
	}
)
// 获取IM的接口地址
export const getIMUrlConfig = () =>
	api.get<IMResponseData<{
		ip:string,
		port:number,
		ssl:number
		timeout:string
	}>>('mytio/im/imserver.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
	}
)

// IM登录
export const postIMLogin = (data:{
	loginname:string
	nick:string
	pd5:string
	sign:string
}) =>
	api.post('mytio/login/inner.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
		data,
		header: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	}
)
// IM退出登录
export const postIMOutLogin = () =>
	api.post('logout.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
		header: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	}
)
export interface IMUserInfo {
	id:number
	nick:string
}
// IM用户信息
export const getIMUser = () =>
	api.get<IMResponseData<IMUserInfo>>('mytio/user/curr.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
	}
)


export const uploadIMFilePath = async (filePath: string,data:{chatlinkid:string}) => {
	return api.upload<IMResponseData>('mytio/chat/img.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
		filePath,
		name: 'uploadFile',
		formData:data,
		custom: {
			isLoading: true
		}
	})

}
export interface HistoryChatMsg {
	atnotreadcount: number
	atreadflag: number
	avatar:string
	bizid:string
	bizrole:number
	chatlinkid:string
	chatmode: number
	chatuptime: string
	focusflag: number
	fromnick: string
	id: string
	joinnum: 2
	lastmsgid: string
	lastmsguid:number
	linkflag: number
	linkid:string
	msgfreeflag:number
	msgresume: string
	msgtype: number
	name: string
	notreadcount: number
	notreadstartmsgid:string
	opernick: ""
	readflag: number
	sendtime: string
	status: number
	sysflag: number
	sysmsgkey: ""
	tonicks: ""
	topflag: number
	toreadflag:number
	uid: number
	viewflag: number
}


// IM聊天列表
export const getIMChatList = () =>
	api.get<IMResponseData<HistoryChatMsg[]>>('mytio/chat/list.tio_x', {
		baseURL:import.meta.env.VITE_IM_HTTP_API_URL,
		withCredentials:true,
	}
)



// 批量查询用户信息
export const getIMUserListInfo = (data : { ids : string }) =>
	api.get<ResponseData<IMUserInfo[]>>('customer/get/photo/batch',
		{
			data
		}
	)
export type QuickReplieItem = {
	shortcut:string
	children:QuickReplieChildrenItem[]
	id:number
}
export type QuickReplieChildrenItem = {
	shortcut:string
	message:string
	id:number,
	pid:number
}
// 快捷回复列表
export const getQuickReplies = () =>
	api.get<ResponseData<QuickReplieItem[]>>('socket/merchant/kefu/quick/replies/select',
		{
			custom: {
				isLoading: true
			}
		}
	)
// 快捷回复分组列表
export const getQuickReplieGroup = () =>
	api.get<ResponseData<QuickReplieItem[]>>('socket/merchant/kefu/quick/replies/list',
		{
			data:{
				pid:0
			},
			custom: {
				isLoading: true
			}
		}
	)
	
// 只传shortcut是添加分组，传pid与message是添加分组的快捷回复
type QuickReplieParams = {
	shortcut:string,
	id?:string
}|{
	id?:string
	message:string,
	pid:number
}


// 新增快捷回复或分组

export const postAddQuickReplie = (data:QuickReplieParams) =>
	api.post('socket/merchant/kefu/quick/replies',
		{
			data,
			custom: {
				isLoading: true
			}
		}
	)
// 编辑快捷回复或分组
// 只传shortcut是编辑分组，传pid与message是编辑分组的快捷回复
export const postEditQuickReplie = (data:QuickReplieParams) =>
	api.put('socket/merchant/kefu/quick/replies',
		{
			data,
			custom: {
				isLoading: true
			}
		}
	)
	// 快捷回复分组列表
	export const getQuickReplieInfo = (id:string) =>
		api.get<ResponseData<QuickReplieChildrenItem>>(`/socket/merchant/kefu/quick/replies/${id}`,
			{
				custom: {
					isLoading: true
				}
			}
		)
	
// 删除快捷回复分组
export const postDelQuickReplie = (id:string) =>
	api.delete(`socket/merchant/kefu/quick/replies/${id}`,
		{
			custom: {
				isLoading: true
			}
		}
	)