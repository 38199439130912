import {wscommand,type MsgType} from '.'



type setParams = {
	// to:string//发送给谁
	body:Record<string,any>|string,//消息主体
	// hasUserMsg?:boolean//是否为用户发出的消息，false则代表是IM的请求
	success?():void//成功回调
	fail?(err:any):void//失败回调
	complete?():void//发送消息结束回调
}
type MsgData = {
	// msgType?:keyof MsgType
	type?:number,
	id?:string
}&Partial<setParams>

export default class SaasIMMessage {
	protected data:MsgData = {}
	constructor(type:keyof MsgType,params:setParams){
		this.data = {...params,type:wscommand[type]}
	}
	// set (params:setParams) {
	// 	this.data = {...this.data,...params}
	// }
	get () {
		return this.data as setParams&{type:number}
	}
}