import zIndex from './zIndex'
export type Tabbar = {
	value?: string|number,
	safeAreaInsetBottom?: boolean,
	border?: boolean,
	zIndex?: number,
	activeColor?: string,
	inactiveColor?: string,
	fixed?: boolean,
	placeholder?: boolean,
	customStyle?:Record<string,any>|string
};
export type TabbarPropsType = Tabbar
const tabbar: Tabbar = {
	// tabbar组件
	safeAreaInsetBottom: true,
	border: true,
	zIndex: zIndex.tabbar,
	activeColor: '#1989fa',
	inactiveColor: '#7d7e80',
	fixed: true,
	placeholder: true

};
export default tabbar;
