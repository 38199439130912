
export type ListItem = {
	anchor?: string
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type ListItemPropsType = ListItem;
const listItem: ListItem = {
	// listItem组件
	anchor: ''
};
export default listItem;
