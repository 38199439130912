import {DefaultProps,type LazyPickerProps,} from  '../../../components/e-lazy-picker/util'

export type LazyPicker = {
	show?: boolean
	showToolbar?: boolean,
	option?: Array<Record<any,any>> ,
	props?:LazyPickerProps,
	title?: string
	itemHeight?: string | number
	cancelText?: string
	confirmText?: string
	cancelColor?: string
	confirmColor?: string
	visibleItemCount?: string | number,
	keyName?:string,
	closeOnClickOverlay?: boolean,
	defaultIndex?: Array<number>,
	immediateChange?: boolean
};
export type LazyPickerPropsType = LazyPicker
const lazyPicker: LazyPicker = {
	// lazyPicker组件
	show: false,
	showToolbar: true,
	option: [],
	props:DefaultProps,
	itemHeight: 44,
	cancelText: '取消',
	confirmText: '确认',
	cancelColor: '#909193',
	visibleItemCount: 5,
	keyName:'text',
	closeOnClickOverlay: false,
	defaultIndex: [],
	immediateChange:false
};
export default lazyPicker;
