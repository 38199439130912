export type CheckboxGroup = {
  name?: string;
  modelValue?: boolean | string | number[];
  shape?: "square" | "circle";
  disabled?: boolean;
  activeColor?: string;
  inactiveColor?: string;
  size?: string | number;
  placement?: "row" | "column";
  labelSize?: string | number;
  labelColor?: string;
  labelDisabled?: boolean;
  iconColor?: string;
  iconSize?: string | number;
  iconPlacement?: "left" | "right";
  borderBottom?: boolean;
  customStyle?: Record<string,any> |string,
  customClass?:string
};
export type CheckboxGroupPropsType = Omit<
  CheckboxGroup,
  "shape" | "placement" | "iconPlacement"
> & {
  shape?: string;
  placement?: string;
  iconPlacement?: string;
};
// checkbox-group组件
const checkboxGroup: CheckboxGroup = {
  modelValue:[],
  shape: "square",
  disabled: false,
  activeColor: "#2979ff",
  inactiveColor: "#c8c9cc",
  size: 18,
  placement: "row",
  labelSize: 14,
  labelColor: "#303133",
  labelDisabled: false,
  iconColor: "#ffffff",
  iconSize: 12,
  iconPlacement: "left",
  borderBottom: false,
};
export default checkboxGroup;
