import type { App } from 'vue'
import { createI18n } from 'vue-i18n'
import Chinese from '@/locale/zh'
import ZhTw from '@/locale/zh-tw'
import enUS from '@/locale/en'
import jp from '@/locale/jp'
import ChineseTabbar from '@/locale/zh/list/tabbarTxt'
import ZhTwTabbar from '@/locale/zh-tw/list/tabbarTxt'
import enTabbar from '@/locale/en/list/tabbarTxt'
import jpTabbar from '@/locale/jp/list/tabbarTxt'
import {LOCALE} from '@/env'
import { setRequestHeader } from '@/common/request'
export type Locale = 'zh_CN' | 'zh_HK'| 'en_US'|'ja_JP'
const messages = {
	zh_CN: Chinese,
	zh_HK:ZhTw,
	en_US:enUS,
	ja_JP:jp
}
const tabbarMessages = {
	zh_CN: ChineseTabbar,
	zh_HK:ZhTwTabbar,
	en_US:enTabbar,
	ja_JP:jpTabbar
}


const i18n = createI18n({
	locale: uni.getStorageSync('locale') || LOCALE,
	messages
})
const init = (app:App) => {
	app.use(i18n)
}

let appLocale:Locale = uni.getStorageSync('locale') || LOCALE

export const getLocale = () => appLocale

export const setLocale = (locale:Locale) => {
	console.log(appLocale,locale)
	if(appLocale === locale)return 
	uni.setStorageSync('locale', locale)
	appLocale = locale
	let _locale:string = locale
	i18n.global.locale = locale
	let tabbar = tabbarMessages[locale]
	uni.setTabBarItem({
	  index: 0,
	  text: tabbar.home,
	})
	uni.setTabBarItem({
	  index: 1,
	  text: tabbar.wallet,
	})
	uni.setTabBarItem({
	  index: 2,
	  text: tabbar.order,
	})
	if (locale === 'zh_CN') {
		_locale = 'zh-Hans'
	} else if (locale === 'zh_HK') {
		_locale = 'zh-Hant'
	} else if (locale === 'ja_JP') {
		_locale = 'ja-JP'
	}
	
	uni.setLocale(_locale)
	setRequestHeader()
}

export default init