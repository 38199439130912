type calculateFn = (arg1: string | number, arg2: string | number) => number;
// 乘
export const accMul: calculateFn = (arg1, arg2) => {
	let m = 0;
	m += deal(arg1);
	m += deal(arg2);
	let r1 = Number(arg1.toString().replace(".", ""));
	let r2 = Number(arg2.toString().replace(".", ""));
	return (r1 * r2) / Math.pow(10, m);
};
// 求小数点后的数据长度
export const deal = (arg: string | number) => {
	let t = 0;
	try {
		t = arg.toString().split(".")[1].length;
	} catch (e) { }
	return t;
};
// 除
export const accDiv: calculateFn = (arg1, arg2) => {
	var t1 = deal(arg1);
	var t2 = deal(arg2);
	var r1 = Number(arg1.toString().replace(".", ""));
	var r2 = Number(arg2.toString().replace(".", ""));
	return (r1 / r2) * Math.pow(10, t2 - t1);
};
// 加
export const accAdd: calculateFn = (arg1, arg2) => {
	let r1 = deal(arg1);
	let r2 = deal(arg2);
	let m = Math.pow(10, Math.max(r1, r2));
	return (accMul(arg1, m) + accMul(arg2, m)) / m;
};
/**
 * 保留小数点几位数, 自动补零, 四舍五入
 * @param {number|string} num: 数值
 * @param {number} digit: 小数点后位数
 * @param {boolean} isRound: 是否四舍五入
 * @returns string
 */
export const numToFixed = (num: string | number, digit: number = 0, isRound: boolean = false) => {
	if (Object.is(parseFloat(`${num}`), NaN)) {
		console.error(`传入的值：${num}不是一个数字`);
		num = 0
	}
	if (isRound) {
		return parseFloat(`${num}`).toFixed(digit)
	}
	
	// 保留几位小数点，就先加上几个0
	// 字符串中间拼接e表示小数点移动digit位，整数右移，负数左移
	let result = +`${num.toString() + new Array(digit).map(() => '0').join('')}e${digit}`;
	result = Math.trunc(result)
	return +`${result}e${-digit}`;
};
/**
 * @description 数字格式化
 * @param {number|string} number 要格式化的数字
 * @param {number} decimals 保留几位小数
 * @param {string} decimalPoint 小数点符号
 * @param {string} thousandsSeparator 千分位符号
 * @returns {string} 格式化后的数字
 */
export const priceFormat = (number: string | number, decimals: number = 0, decimalPoint: string = '.', thousandsSeparator: string = ','): string => {
	const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
	let s = `${numToFixed(number, decimals, false)}`.split('.')


	const re = /(-?\d+)(\d{3})/
	while (re.test(s[0])) {
		s[0] = s[0].replace(re, `$1${thousandsSeparator}$2`)
	}

	if ((s[1] || '').length < prec) {
		s[1] = s[1] || ''
		s[1] += new Array(prec - s[1].length + 1).join('0')
	}
	return s.join(decimalPoint)
}

/**
 * @description 如果value小于min，取min；如果value大于max，取max
 * @param {number} min
 * @param {number} max
 * @param {number} value
 * @returns {number} 
 */
export const numberRandom = (min: number = 0, max: number = 0, value: number = 0): number => {
	return Math.max(min, Math.min(max, Number(value)));
};

/**
 * @description 如果value小于min，取min；如果value大于max，取max
 * @param {number} value
 * @returns {string} 
 */
export const numberFormatter = (value: string | number):string => `${value}`.replace(/[^0-9.]/g, '')