import appSocket from './socket'
import { nanoid } from '../'
import message from './message'

import {getIMUserId,wscommand,wsBs,type ReceiveMsg } from '.'

export type newReceiveMsg = ReceiveMsg&{
	msn:string
}

const defaultConfig = {
	url: '',
	cookieName:'',
	heartbeatTimeout:5000//心跳超时时间，单位：毫秒
}

type initParams = {
	onOpened?(): void//IM已打开事件,即IM登录成功
	onOutLogin?(): void//退出登录
	onQuit?(): void//IMtoken失效，强制退出
	onReconnect?(): void//socket重连事件
	onSocketConnected?(): void//socket已连接事件
	onTextMessage?(msg: ReceiveMsg): void//收到文本类型消息事件
	onEmojiMessage?(msg: ReceiveMsg): void//收到带表情的文本类型消息事件
	onImageMessage?(msg: ReceiveMsg): void//收到图片类型消息事件
	onVideoMessage?(msg: ReceiveMsg): void//收到视频类型消息事件
	onAudioMessage?(msg: ReceiveMsg): void//收到语音类型消息事件
	onCustomMessage?(msg: ReceiveMsg): void//收到自定义类型消息事件
	onError?(data: { type: string, error: any }): void//各种异常报错事件
}

const sendCb : message[] = []


class saasIM {
	private params: initParams = {}
	private socket: appSocket | null = null;
	config = defaultConfig
	isInit = false
	isShake = false
	protected isOpen = false
	message = message
	init(params: initParams) {
		this.params = params
		if (!this.isInit) {
			this.socket = new appSocket({
				url: this.config.url,
				heartbeatDuration:this.config.heartbeatTimeout
			})
			this.socket.on('appSocketClose', this.socketClose)
		}
		params.onReconnect && this.socket?.on('reconnect', params.onReconnect)
		this.socket?.on('open', this.handleSocketOpen)
		params.onError && this.socket?.on('error', params.onError)
		this.socket?.on('message', this.handleNewMsg)

	}
	private socketClose =  () => {
		this.isInit = false
	}
	
	private handleSocketOpen = () => {
		this.isInit = true
		this.params.onSocketConnected?.()
	}
	private shakeInit =  () => {
		this.isShake = true
		for(let i = 0;i<sendCb.length;i++){
			this.send(sendCb[i])
			sendCb.splice(i,1)
		}
		if(sendCb.length > 0){
			this.shakeInit()
		}
	}
	open() {
		this.isOpen = true
		this.params.onOpened?.()
		// this.socket?.send({
		// 	data: {
		// 		action: 'auth',
		// 		body: {
		// 			authorization
		// 		},
		// 		tenant: uni.getStorageSync("tenant")
		// 	},
		// 	success: () => {
		// 		this.isOpen = true
		// 		this.params.onOpened?.()
		// 	},
		// 	fail: (err) => {
		// 		console.log(err)
		// 	}
		// })
	}
	// 发送聊天消息
	send(msg: message) {
		let _msg = msg.get()
		if(!this.isShake){
			sendCb.push(msg)
			return 
		}
		// let msgData = {
		// 		action: 'kefu',
		// 		body: {
		// 			toId: _msg.to,
		// 			formId:getIMUserId(),
		// 			msn: {
		// 				msg: _msg.body,
		// 				id: _msg.id
		// 			},
		// 			msnType: _msg.type,
		// 		},
		// 		tenant: uni.getStorageSync("tenant")
		// }
		// if(_msg.msgType === 'quick'){
		// 	// 快捷消息无需通过socket发送
		// 	msgStorage.saveSendMsg(msgData.body,_msg.msgType)
		// 	_msg.success?.()
		// 	return
		// }
		this.socket?.send(_msg.type,{
			data: _msg.body,
			success: () => {
				// msgStorage.saveSendMsg(_msg.body,_msg.type)
				_msg.success?.()
			},
			fail: _msg.fail,
			complete: _msg.complete
		})
	}
	// 收到新消息
	private handleNewMsg = (msg: any,commandName:string) => {
		const bshandler = wsBs[commandName]//window[commandName];
		if(!bshandler){
			console.log(`找不到名称为${commandName}的函数`)
			return 
		}
		bshandler.call(this,msg)
		// if(commandName === 'WxHandshakeResp'){
		// 	// 收到握手响应
			
		// }else{
		// 	if (msg.action === 'kefu') {
		// 		let newMsg = {
		// 			...msg.body,
		// 			msn:JSON.parse(msg.body.msn)
		// 		}
		// 		// 聊天消息
		// 		this.handleNewUserMsg(newMsg)
		// 	} else if (msg.action === 'auth'||msg.action === 'close') {
		// 		//登录失效
		// 		this.socket?.close().then(res => {
		// 			this.params.onQuit?.()
		// 		})
		// 	}
		// }
		
	}
	// // 收到新的用户消息
	// private handleNewUserMsg = (msg: ReceiveMsg) => {
	// 	switch (msg.msnType) {
	// 		case 1:
	// 			this.params.onTextMessage?.(msg)
	// 			break
	// 		case 2:
	// 			this.params.onEmojiMessage?.(msg)
	// 			break
	// 		case 3:
	// 			this.params.onImageMessage?.(msg)
	// 			break
	// 		case 4:
	// 			this.params.onAudioMessage?.(msg)
	// 			break
	// 		case 5:
	// 			this.params.onCustomMessage?.(msg)
	// 			break
	// 	}
	// }
	getUniqueId() {
		return `${new Date().getTime()/1000}_${nanoid(10)}`
	}
	outLogin () {
		this.socket?.close()
		this.params.onOutLogin?.()
	}
}

const IM = new saasIM()

export type SaasIM = typeof IM

export default IM
