import { getImageDomain,getMapKey } from '@/common/request/api'
import { setLocale,type Locale } from '@/common/init/i18n'
import {useGlobal,type BaseVariable} from '@/common/stores'
import {loadMapScript} from '@/common/utils/map'

type CB = () => void

class Dispatcher {
	// 初始化的请求是否完成
	isRequestComplete = false
	// app初始化是否完成，完成代表可调用pinia存储相关的初始化变量
	isAppInitComplete = false
	dispCbs:Array<CB> = []
	on (cb:CB) {
		// 初始化的请求已经完成，直接回调
		if(this.isRequestComplete){
			cb()
		}else{
			// 未完成则存入队列中，待初始化的请求完成后统一回调
			this.dispCbs.push(cb)
		}
	}
	requestComplete (cb:CB) {
		this.isRequestComplete = true
		this.dispCbs.unshift(cb)
		this.checkCompleteStatus()
	}
	appInitComplete () {
		this.isAppInitComplete = true
		this.checkCompleteStatus()
	}
	checkCompleteStatus () {
		
		if(this.isRequestComplete && this.isAppInitComplete){
			this.dispCbs.forEach(item => item())
		}
	}
}

export const baseInitDispatcher = new Dispatcher()

const init = async () => {
	let res = await getImageDomain()
	let baseVariable:BaseVariable = {
		unitEn:'￥',
		countryCode:'zh_CN',
		host:'',
		applicationAll:[]
	}
	
	if (res.code === 200) {
		if (res.siteCurrency) baseVariable.unitEn = res.siteCurrency
		if (res.siteClient) baseVariable.countryCode = res.siteClient
		baseVariable.host = res.data
		baseVariable.applicationAll = res.applicationAll
	}
	let mapRes = await getMapKey()
	if(mapRes.code === 200){
		if(process.env.NODE_ENV === 'development'){
			mapRes.data = ['YENBZ-UWUCQ-VIJ5V-G6DCI-7BDO5-RPFBJ']
		}
		loadMapScript(mapRes.data.key)
	}
	// setLocale(baseVariable.countryCode)
	setLocale('zh_CN')
	baseInitDispatcher.requestComplete(() => {
		const {setGlobal} = useGlobal()
		setGlobal(baseVariable)
	})
	
	
}
export default init