import api, { type ResponseData } from '../index'
import type { pageParam } from '../type'

export type GoodsCategory = {
	id: number,
	name: string,
	// icon:string,
	parid:number
}
export type GoodsCategoryArray = Array<GoodsCategory>
// 一级商品分类
export const getGoodsCategory = (data: {type:'1'|'2'}) =>
	api.get<ResponseData<GoodsCategoryArray>>('system/category/tree',
		{
			data,
			custom: {
				isLoading: true
			}
		}
	)
// 二级、三级商品分类
export const getGoodsCategoryChildren = (id:number|string) =>
	api.get<ResponseData<GoodsCategoryArray>>(`system/category/tree/${id}`,
		{
			custom: {
				isLoading: true
			}
		}
	)

