import api, { type ResponseData } from '../index'
import type { pageParam } from '../type'

export type UserInfo = {
	headerImg: string
	phone: string
}

// 用户信息
export const getUserInfo = () =>
	api.get<ResponseData<UserInfo>>('merchant/info')
export type UserRouter = {
	name: string;
	children?: Array<UserRouter>
};
// 用户资产信息
export const getUserBalance = () =>
	api.get<ResponseData<{
		balance: number,
		deposit: number
	}>>('merchant/home/balance')



// 用户路由，选择店铺后必须调用该接口，后端才能正确加载对应店铺权限
export const getRouter = (data: { storeId: string }) =>
	api.get<ResponseData<Array<UserRouter>>>('merchant/store/menu/list/menu/router', {
		data
	})
export type UserStore = {
	createTime: string;
	hasOwn: number;
	id: string;
	logo: string;
	name: string;
	status: number;
	statusApply: number;
	statusBusiness: number;
	type: number;
};
export type UserStoreArray = Array<UserStore>
// 用户店铺列表
export const getUserStoreList = (data?: { type?: string }) =>
	api.get<ResponseData<UserStoreArray>>('merchant/store/list', {
		data,
		custom: {
			isLoading: true
		}
	})
// 删除店铺
export const postRemoveStore = (id: number) =>
	api.delete(`/merchant/store/${id}`, {
		custom: {
			isLoading: true
		}
	})
export type StoreDetail = {
	id: string,
	name: string,
	logo: string,
	description: string,
	beginTime: string,
	endingTime: string,
	realScenePhoto: string,
	licenseAddrProvince: string
	licenseAddrCity: string
	licenseAddrArea: string
	licenseAddrDetail: string
	licenseAddrText: string
	licenseType: number
	licenseImages?: Array<string>
	creditCode: string
	legalPrincipalName: string
	legalPrincipalIdcard: string
	legalPrincipalIdcardImages?: Array<string>
	createTime: string,
	imInfo:{
		loginname:string
		nick:string
		pd5:string
		sign:string,
		userThirdId:string
		userType:1|2
	}
}
// 店铺详情
export const getStoreDetail = () =>
	api.get<ResponseData<StoreDetail>>('merchant/store/get', {
		custom: {
			isLoading: true
		}
	})


export type accountInfoItem = {
	cost: number
	createTime: string
	orderId: string
	payCode: string
	paymentMode: string
	productName: string
	remark: string
	state: number
	thirdTransactionNo: string
	hasIncome:0|1,
	id:number
}
type FundParams = {
	'params[beginTime]':string,
	'params[endTime]':string,
	hasIncome?:0|1
}

// 余额明细
export const getFundDetailsList = (data: pageParam&FundParams) =>
	api.get<ResponseData<Array<accountInfoItem>>>('merchant/store/assets/amount/detail/list', {
		data
	})

// 冻结余额明细
export const getFrozenDetailsList = (data: pageParam&FundParams) =>
	api.get<ResponseData<Array<accountInfoItem>>>('customer/api/account/frozen/details/yue', {
		data
	})
export type WithdrawalItem = {
	amount: number
	createTime: string
	collectionAccountName: string
	collectionAccountNo: string
	createName: string
	payCode: string
	rejectRemark: string
	state: number
	strategyName: string
}
// 提现记录
export const getWithdrawalDetailsList = (data: pageParam) =>
	api.get<ResponseData<Array<WithdrawalItem>>>('merchant/store/assets/withdrawal/details', {
		data
	})



export type BankCardItem = {
	id: number
	bankCardNo: string
	bankTypeLogo: string
	bankTypeName: string
}
// 用户已绑定的银行卡列表
export const getBankCardList = () =>
	api.get<ResponseData<Array<BankCardItem>>>('merchant/store/assets/overview/bankcard/list', {
		custom: {
			isLoading: true
		}
	})
// 添加银行卡
export const postAddBindBank = (data:{
	fullName: string,
	idCardNo: string,
	phone: string,
	bankTypeId: string,
	bankCardNo: string,
}) =>
	api.post('merchant/store/assets/overview/bankcard/add', {
		data,
		custom: {
			isLoading: true
		}
	})
// 删除银行卡
export const postDleBankCard = (data: Array<number>) =>
	api.post('merchant/store/assets/overview/bankcard/delete', {
		data,
		custom: {
			isLoading: true
		}
	})

export type bankItem = {
	bankname: string,
	id: number,
	logo: string
}
// 可绑定的银行列表
export const getBankList = () =>
	api.get<ResponseData<bankItem[]>>('system/api/basics/bankTypeList', {
		custom: {
			isLoading: true
		}
	})
export type ExtractParams = {
	amount: string,
	bankId: number,
	smsCode: string,
}

// 提现
export const postExtract = (data: ExtractParams) =>
	api.post('merchant/store/assets/withdrawal/add', {
		data,
		custom: {
			isLoading: true
		}
	})
// 提现规则
export const getExtractRule = () =>
	api.get<ResponseData<{
		scale: number,
		minval: number,
		maxval: number
	}>>('system/api/basics/cash/rule/1', {
		custom: {
			isLoading: true
		}
	})
// 提现记录
export const postExtractHistory = () =>
	api.get('customer/api/cash')
	
// 获取提现验证码
export const postSms = () =>
	api.post('merchant/store/assets/withdrawal/sms')

