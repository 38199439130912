import type {CalendarMonth,CalendarMonthPropsType} from './calendarMonth'

export type Calendar = CalendarMonth&{
	format?:string,
	showLunar?:boolean,
	conversion?:boolean,
	formatter?(v:string):string
};
export type CalendarPropsType = CalendarMonthPropsType&{
	
};
const calendar: Calendar = {
  // calendar组件
  mode:'single',
  startText:'开始',
  endText:'结束',
  rowHeight:56,
  minDate:0,
  maxDate:"7258089600000",
  maxCount:Infinity,
  readonly:false,
  maxRange:Infinity,
  showRangePrompt:true,
  allowSameDay:true,
  sundayStart:false,
  nowDate:new Date(),
  showNowDateDot:true,
  showLunar:false,
  conversion:false
};
export default calendar;
