import zIndex from './zIndex'
export type LoadingPage = {
	loadingText?: string|number
	image?: string
	loadingMode: 'circle'|'spinner'|'semicircle'
	loading: false,
	bgColor?: string
	color?: string
	fontSize?: string|number
	iconSize?: string|number
	loadingColor?: string
	zIndex?:string|number
}

export type LoadingPagePropsType = Omit<LoadingPage,'loadingMode'>&{
	loadingMode?:string
}
const loadingPage: LoadingPage = {
	// loading-icon组件
	loadingText: '正在加载',
	image: '',
	loadingMode: 'circle',
	loading: false,
	bgColor: '#ffffff',
	color: '#C8C8C8',
	fontSize: 19,
	iconSize: 28,
	loadingColor: '#C8C8C8',
	zIndex:zIndex.loadingPage
};
export default loadingPage;
