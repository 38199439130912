/*收到握手响应，这个时候可以开始做业务上的事了*/
import {useIM} from '@/common/stores';

const WxHandshakeResp = function (bodyObj:{cid:string})  {
	console.log('收到握手响应')
	const IMStore = useIM()
	IMStore.setChannelContextid(bodyObj.cid)
	this.shakeInit()
	

};
export default WxHandshakeResp;