
//请求成功的状态码
export const REQUEST_SUCCESS_CODE = 200
// 接口地址
export const REQUEST_BASE_URL = import.meta.env.VITE_API_URL
// 租户key
export const APP_TENANT = import.meta.env.VITE_APP_TENANT
// IM接口地址
export const IM_API_URL = import.meta.env.VITE_IM_API_URL
// 颜色不要带大写字母，虽然html能够识别，但是某些场景下，比对元素样式是否与THEME_PRIMARY一致时，元素的样式都是小写，此时THEME_PRIMARY如果是大写就会导致两者对比不一致，如商品的富文本编辑器
export const THEME_PRIMARY = '#105cfb'

export const LOCALE = 'zh_CN'
// app开放的应用列表
export const AppRouterMap = {
	marketing:['seckill','group','coupon','cityAppointment','timesCard']
}
// 使用腾讯还是谷歌地图 腾讯为'tencent' 谷歌为google
export const APP_MAP_TYPE = 'tencent'
// 当前项目默认定位的经纬度
export const DEFAULT_LOCATION = [39.9096,116.3972]