export default {
	title1: '预约管理',
	title2: '员工管理',
	title3: '预约服务',
	route1:'预约记录',
	route2:'预约设置',
	route3:'服务提成',
	route4:'服务员工管理',
	route5:'员工排班',
	route6:'服务管理',
	serveManageList:'服务列表',
	serveManageOrder:'服务订单',
	addServe:'添加服务',
	serve:'服务',
	serveGoodsSearchTips:'请输入服务名称搜索',
	serveGoodsLabel1:'预约方式',
	serveGoodsLabel2:'预约到店',
	serveGoodsLabel3:'预约上门',
	serveGoodsLabel4:'上门费',
	serveGoodsTips1:'请选择服务商品的预约方式',
	serveGoodsTips2:'请输入服务商品的上门费',
	historyLabel1:'服务名称',
	historyLabel2:'服务时间',
	historyLabel3:'预约方式',
	historyLabel4:'服务用户',
	historyLabel5:'预约顾问',
	historyLabel6:'预约项目',
	historyLabel7:'上门信息',
	appointmentType1:'预约到店',
	appointmentType2:'预约上门',
	check:'核销',
	change:'更改',
	serveTime:'预约时间',
	dataEmpty:'暂无数据',
	searchByStaffName:'请输入员工名称搜索',
	addStaff:'添加员工',
	staffName:'员工姓名',
	staffName1:'请输入员工姓名',
	staffPhone:'员工手机号',
	staffPhone1:'请输入员工手机号',
	staffPosition:'员工职位',
	staffPosition1:'请输入员工职位',
	staffSex:'员工性别',
	staffHead:'员工头像',
	staffHead1:'请上传750*750的图片',
	remark:'备注',
	remark1:'请输入不超过200字的备注',
	male:'男',
	female:'女',
	editHistoryTips:'请选择预约时间',
	appservice:'关联服务',
	editStaff:'编辑员工',
	saveSuccess:'保存成功',
	addStaffSuccess:'员工添加成功',
	addStaffSerive:'添加关联项目',
	checkAll:'全选',
	batchAdd:'批量添加排班',
	classesDelTips:'确认要删除该员工的排班吗？',
	classesSuccess:'排班成功',
		commissionDetail:'提成明细',
	commissionStrategy:'提成策略',
	dataOverview:'数据概览',
	allServiceMoney:'总服务业绩',
	allServiceCommission:'总服务提成',
	allServicePeople:'总服务人数（人）',
	allServiceTimes:'总服务次数（次）',
	settled:'已结算',
	unsettled:'未结算',
	declare:'手动报单',
	commissionEmployee:'提成员工',
	commissionState:'提成状态',
	settleTime:'结算时间',
	serviceTime:'服务时间',
	selectCommissionEmployee:'选择提成员工',
	commission:'提成',
	userNickname:'用户昵称',
	userPhone:'用户电话',
	projectMoney:'项目金额',
	declareEmployee:'报单员工',
	plaseSelect:'请选择报单员工',
	declareMoney:'报单价格',
	declareMoney1:'请输入报单价格',
	declareCommiss:'提成金额',
	declareCommiss1:'请输入提成金额',
	declareProduct:'报单商品',
	declareProduct1:'请输入报单商品',
	addCommissionConfig:'增加提成项目设置',
	commissionConfigLabel1:'提成条件',
	commissionConfigLabel2:'项目提成',
	commissionConfigLabel3:'员工名称',
	commissionConfigLabel4:'提成项目',
	commissionConfigLabel5:'提成计算',
	commissionConfigLabel6:'比例',
	commissionConfigLabel7:'固额',
	addStaffItem:'增加员工项',
	addCommissionConfigGoods:'添加提成项目',
	addCommissionConfigError1:'请先添加提成项目',
	addCommissionConfigError2:'请输入提成计算',
	addCommissionConfigError3:'请先添加员工',
	classesManagement:'班次管理',
	scheduleMangement:'排班管理',
	addClasses:'添加班次',
	peopleUsed:'人使用',
	classesName:'班次名称',
	classesTime:'班次时间',
	classesName1:'请输入班次名称',
	classesTime1: '请选择班次时间'
}