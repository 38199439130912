export default {
  loginTitle: "ストアログイン",
  forgetTitle: "パスワードをお忘れですか？",
  loginTips1: "携帯番号を入力してください",
  loginTips2: "パスワードを入力してください",
  login: "ログイン",
  forgetTips1: "検証コードを入力してください",
  forgetTips2: "6～12桁の英数字の組み合わせを入力してください",
  forgetTips3: "ログインパスワードを確認してください",
  forgetTips4: "ログインパスワードを入力してください",
  forgetTips5: "6桁の数字を入力してください",
  forgetTips6: "検証コードを入力してください",
  pawTips1: "支払いパスワードを確認してください",
  pawTips2: "支払いパスワードを入力してください",
  getCode: "検証コードを取得",
  label1: "携帯番号",
  label2: "検証コード",
  label3: "ログインパスワード",
  label4: "パスワードを確認",
  label5: "支払いパスワード",
  setPayPaw: "支払いパスワードを設定する",
  editPayPaw: "支払いパスワードを変更する",
  editLoginPaw: "ログインパスワードを変更する",
  nowVersion: "現在のバージョン",
  delCache: "キャッシュをクリア",
  delCacheTips: "アプリのキャッシュを削除しますか？",
  delSuccess: "クリアが完了しました",
  versionIsNew: "現在のバージョンは最新です",
  upgradeTxt: "更新中...",
  upgradeSuccess: "アプリが更新されました。再起動します。",
  upgradeError: "インストールに失敗しました。再試行してください。",
  newVersion: "新しいバージョンが見つかりました",
  upgrade: "今すぐアップグレード",
  unread: "未読のメッセージ：",
  allRead: "すべて既読",
  goRegisterTips: "まだアカウントをお持ちでない場合、今すぐ登録してください >",
  goLoginTips: "すでにアカウントをお持ちの場合、今すぐログインしてください >",
  registerTitle: "ストアの登録",
  agreementTxt1: "以下の規約に同意する",
  agreementTxt2: "「ログインサービス契約書」",
  agreementTxt3: "と",
  agreementTxt4: "「個人情報保護ポリシー」",
  agreementTips: "利用規約に同意してください",
  registerSuccess: "登録が完了しました",
  forgetSuccess: "設定が完了しました",
  switchStore: "ストアを切り替える",
  out: "ログアウト",
  outTips: "ログアウトしますか？",
  noticeEmpty: "通知はありません",
  register: "登録",
  audio: "音声",
  yesterday: "昨日",
  album: "アルバム",
  goods: "商品",
  quickReply: "早期返信",
  send: "送信",
  IMPlaceholder: "ユーザーのニックネームを入力して検索してください",
  IMEmpty: "メッセージはありません",
  IMSetting: "メッセージ設定",
  quickAdd: "早期返信の追加",
  quickGroupAdd: "新しいグループを作成する",
  quickGroupEdit: "グループを編集する",
  quickGroupPlaceholder: "最大10文字まで入力できます",
  quickGroupTips: "グループ名を入力してください",
  quickManage: "グループを管理する",
  delQuickGroupTips: "現在のグループを削除しますか？",
  editQuickTitle: "早期返信の編集",
  addQuickPlaceholder: "有効な返信を作成することで、作業効率が向上します",
  selectGroup: "グループを選択してください",
  addQuickTips1: "返信内容を入力してください",
  addQuickTips2: "グループを選択してください",
  addQuickSuccess: "返信が正常に作成されました",
  editQuickSuccess: "返信が正常に編集されました",
  delQuickTips: "現在の返信を削除しますか？",
  selectGoods: "送信する商品を選択してください",
  searchGoodsPlaceholder: "商品名を入力して検索してください",
  orderMsgTitle: "この注文に関する質問があります",
};
