export default {
	empty: '暫無優惠券',
	type1: '滿',
	type2: '無門檻',
	sub: '减',
	state1: '未開始',
	state2: '進行中',
	state3: '已過期',
	timeState1: '領券後立即生效',
	timeState2: '有效期',
	txt1: '剩餘：',
	txt2: '已領：',
	txt3: '打',
	txt4: '折',
	txt5: '最多優惠',
	invalidTips: '確定要失效該優惠券嗎？',
	editTitle: '編輯優惠券',
	addTitle: '添加優惠券',
	label1: '優惠券類型',
	label2: '優惠券名稱',
	label3: '適用商品',
	label4: '使用門檻',
	label5: '訂單滿',
	label6: '，使用',
	label7: '滿减金額',
	label8: '發放數量',
	label9: '張',
	label10: '領券時間',
	label11: '用券時間',
	label12: '限領次數',
	label13: '次',
	label14: '優惠折扣',
	label15: '限領身份',
	label16: '所有會員可領',
	label17: '指定身份',
	label18: '滿减券',
	label19: '折扣券',
	label20: '已編輯',
	label21: '全部商品可用',
	label22: '指定分類可用',
	label23: '指定商品可用',
	formTips1: '請輸入最多12字元的標題',
	formTips2: '請選擇適用商品',
	formTips3: '請輸入使用門檻',
	formTips4: '請輸入滿减金額',
	formTips5: '請輸入發放數量',
	formTips6: '請選擇開始時間',
	formTips7: '請選擇結束時間',
	formTips8: '請輸入限領次數',
	release: "確定添加",
	addClassify: '添加分類',
	addGoods: '添加商品',
	select: '選擇分類',
	selectTips: '請選擇',
	userSearchTips: '請輸入用戶ID蒐索',
	userEmpty: '暫無人領取優惠券',
	dataSearchTips: '請輸入訂單編號/用戶ID蒐索',
	dataEmpty: '暫無人使用優惠券',
}