import type { ThemeType } from '../props'
import type { OpenTypeProps } from './types'

export type Text = {
	type?: ThemeType,
	show?: boolean,
	text?: string | number,
	prefixIcon?: string,
	suffixIcon?: string,
	mode?: 'price' | 'phone' | 'name' | 'date' | 'link',
	href?: string,
	format?: ((v: string) => string) | string;
	call?: boolean,
	bold?: boolean,
	block?: boolean,
	lines?: string | number,
	color?: string,
	size?: number | string,
	iconStyle?: Record<string, any>,
	decoration?: 'none' | 'underline' | 'line-through',
	margin?: Record<string, any> | number | string,
	lineHeight?: string | number,
	align?: 'left' | 'center' | 'right',
	wordWrap?: 'normal' | 'break-word' | 'anywhere',
	customStyle?: Record<string,any> |string,
	customClass?:string

} & OpenTypeProps;
export type TextPropsType = Omit<Text, 'type' | 'mode' | 'openType' | 'decoration' | 'align' | 'wordWrap' | 'lang' | 'formType'> & {
	type?: string
	mode?: string
	openType?: string
	decoration?: string
	align?: string
	wordWrap?: string
	lang?: string
	formType?: string
}
const text: Text = {
	// text组件
	show: true,
	text: '',
	prefixIcon: '',
	suffixIcon: '',
	href: '',
	format: '',
	call: false,
	bold: false,
	block: false,
	lines: '',
	color: '#303133',
	size: 15,
	iconStyle: {
		fontSize: '15px'
	},
	decoration: 'none',
	margin: 0,
	align: 'left',
	wordWrap: 'normal'

};
export default text;
