export type Icon = {
  name?: string;
  color?: string;
  size?: string | number;
  bold?: boolean;
  index?: string;
  hoverClass?: string;
  customPrefix?: string;
  label?: string;
  labelPos?: "bottom" | "top" | "left" | "right";
  labelSize?: string | number;
  labelColor?: string;
  space?: string | number;
  imgMode?: string;
  width?: string | number;
  height?: string | number;
  top?: string | number;
  stop?: boolean;
  customStyle?: Record<string,any> |string,
  customClass?:string
};

export type IconPropsType = Omit<Icon, "labelPos"> & {
  labelPos?: string;
};
const icon: Icon = {
  // icon组件
  color: "#606266",
  size: "16px",
  bold: false,
  customPrefix: "eicon",
  labelPos: "right",
  labelSize: "15px",
  labelColor: "#606266",
  space: "3px",
  top: 0,
  stop: false,
};
export default icon;
