export default {
	storeType1:'線上店鋪',
	storeType2:'線下店鋪',
	storeTypeTips1:'為商家提供解决網上開店問題',
	storeTypeTips2:'為商家提供線下店鋪管理銷售',
	storeState1:'稽核中',
	storeState2:'不通過',
	storeState3:'營業中',
	storeState4:'已打烊',
	addStore:'創建店鋪',
	delStoreTips:'確定要删除該店鋪嗎？',
	selectTypeTitle:'請選擇需要入駐的店鋪類型',
	storeStepTxt1:'經營資訊',
	storeStepTxt2:'店鋪資訊',
	storeStepTxt3:'提交稽核',
	next:'下一步',
	prev:'上一步',
	storeStepLabe1:'請選擇你的主營類目',
	storeStepLabe2:'店鋪名稱',
	storeStepLabe3:'執照類型',
	storeStepLabe4:'營業執照',
	storeStepLabe5:'組織機構代碼',
	storeStepLabe6:'營業執照地址',
	storeStepLabe7:'營業執照詳細地址',
	storeStepLabe8:'店鋪門頭照',
	storeStepLabe9:'營業時間',
	storeStepLabe10:'法人姓名',
	storeStepLabe11:'法人身份證號碼',
	storeStepLabe12:'法人身份證照片',
	storeStepLabe13:'負責人姓名',
	storeStepLabe14:'負責人電話',
	storeStepLabeTips1:'請選擇類目',
	storeStepLabeTips2:'請輸入店鋪名稱',
	storeStepLabeTips3:'請選擇執照類型',
	storeStepLabeTips4:'請上傳營業執照',
	storeStepLabeTips5:'請輸入組織機構代碼',
	storeStepLabeTips6:'請選擇營業執照地址',
	storeStepLabeTips7:'請輸入營業執照詳細地址',
	storeStepLabeTips8:'請上傳店鋪門頭照',
	storeStepLabeTips9:'請選擇營業時間',
	storeStepLabeTips10:'請輸入法人姓名',
	storeStepLabeTips11:'請輸入法人身份證號碼',
	storeStepLabeTips12:'請上傳法人身份證照片',
	storeStepLabeTips13:'請輸入店主姓名',
	storeStepLabeTips14:'請輸入店主電話',
	storeStepLabeTips15:'請先閱讀並同意《商家入駐協議》',
	imgSizeTips:'圖片尺寸800*800以上，大小5mb以內，支持png，jpeg，gif格式',
	agreement:'《商家入駐協議》',
	submit:'提交資料',
	to:'至',
	logo:'店鋪頭像',
	name:'店鋪名',
	state:'營業狀態',
	selectAddress:'選擇所在地區',
	select:'請選擇',
	resubmit:'重新提交',
	applying:'店鋪正在稽核中，請耐心等候…',
	applicationError:'店鋪申請失敗，請重新提交申請資料',
	legalPrincipalIdcardImage:'法人證件照',
	edit:'編輯資料',
	save:'保存',
	startTime:'開始時間',
	endTime:'結束時間'
}