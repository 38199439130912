import {reactive } from 'vue'
import {getGoodsCategory,getGoodsCategoryChildren} from '@/common/request/api'
import type {GoodsCategoryArray} from '@/common/request/api'

type GoodsCategory = {
	onLine:{
		isGet:boolean,
		value:GoodsCategoryArray
	},
	offLine:{
		isGet:boolean,
		value:GoodsCategoryArray
	}
}
type GoodsCategoryChild = {
	onLine:Map<string,GoodsCategoryArray>,
	offLine:Map<string,GoodsCategoryArray>
}

// 过滤掉无用字段，减少缓存的占用空间
const filter = (list:GoodsCategoryArray) => list.map(item => {
	return {
		id: item.id,
		name: item.name,
		parid:item.parid
	}
})



const goodsOneCategory:GoodsCategory = {
	onLine:{
		// 每次运行只请求一次商品一级分类列表
		isGet:false,
		value:uni.getStorageSync('appGoodsOnLineOneCategory')||[]
	},
	offLine:{
		// 每次运行只请求一次商品一级分类列表
		isGet:false,
		value:uni.getStorageSync('appGoodsOffLineOneCategory')||[]
	}
}
// 记录当前运行时二级与三级分类是否已经请求过，请求过则不再请求
// 前两位为二级的线上线下id，后两位为三级的线上线下id
const requestCategoryChildId = [new Set(),new Set([]),new Set(),new Set([])]

const goodsTwoCategory:GoodsCategoryChild = {
	onLine:new Map(uni.getStorageSync('appGoodsOnLineTwoCategory')),
	offLine:new Map(uni.getStorageSync('appGoodsOffLineTwoCategory'))
}
const goodsThreeCategory:GoodsCategoryChild = {
	onLine:new Map(uni.getStorageSync('appGoodsOnLineThreeCategory')),
	offLine:new Map(uni.getStorageSync('appGoodsOffLineThreeCategory'))
}


const getGoodsOneCategory = (type:'1'|'2') => new Promise<GoodsCategoryArray>((resolve) => {
	const oneCategory = type === '1' ? goodsOneCategory.onLine : goodsOneCategory.offLine
	if(oneCategory.isGet){
		resolve(oneCategory.value)
	}else{
		getGoodsCategory({
			type
		}).then(res => {
			oneCategory.isGet = true
			oneCategory.value = res.data
			if(type === '1'){
				uni.setStorageSync('appGoodsOnLineOneCategory', filter(res.data))
			}else{
				uni.setStorageSync('appGoodsOffLineOneCategory', filter(res.data))
			}
			resolve(oneCategory.value)
		}).catch((err) => {
			console.error(err)
			resolve([])
		})
	}
})

const getGoodsTwoCategory = (type:'1'|'2',id:number) => new Promise<GoodsCategoryArray>((resolve) => {
	let twoCategory:Map<string,GoodsCategoryArray>
	let index:0|1
	let storageKey = ''
	if(type === '1'){
		twoCategory = goodsTwoCategory.onLine 
		index = 0 
		storageKey = 'appGoodsOnLineTwoCategory'
	}else{
		twoCategory =  goodsTwoCategory.offLine
		index = 1
		storageKey = 'appGoodsOffLineTwoCategory'
	}
	const isGet = requestCategoryChildId[index].has(id)
	if(isGet){
		resolve(twoCategory.get(`${id}`)||[])
	}else{
		getGoodsCategoryChildren(id).then(res => {
			twoCategory.set(`${id}`,filter(res.data))
			requestCategoryChildId[index].add(id)
			uni.setStorageSync(storageKey, Array.from(twoCategory))
			resolve(res.data)
		}).catch((err) => {
			console.error(err)
			resolve([])
		})
	}
})

const getGoodsThreeCategory = (type:'1'|'2',id:number) => new Promise<GoodsCategoryArray>((resolve) => {
	let twoCategory:Map<string,GoodsCategoryArray>
	let index:2|3
	let storageKey = ''
	if(type === '1'){
		twoCategory = goodsThreeCategory.onLine 
		index = 2 
		storageKey = 'appGoodsOnLineThreeCategory'
	}else{
		twoCategory =  goodsThreeCategory.offLine
		index = 3
		storageKey = 'appGoodsOffLineThreeCategory'
	}
	const isGet = requestCategoryChildId[index].has(id)
	if(isGet){
		resolve(twoCategory.get(`${id}`)||[])
	}else{
		getGoodsCategoryChildren(id).then(res => {
			twoCategory.set(`${id}`,filter(res.data))
			requestCategoryChildId[index].add(id)
			uni.setStorageSync(storageKey, Array.from(twoCategory))
			resolve(res.data)
		}).catch((err) => {
			console.error(err)
			resolve([])
		})
	}
})

export const useGoodsCategory = () => {
	const oneCategory = reactive<GoodsCategoryArray>([])
	const twoCategory = reactive<GoodsCategoryArray>([])
	const threeCategory = reactive<GoodsCategoryArray>([])
	const getOneCategory = async (type:'1'|'2') => {
		let res = await getGoodsOneCategory(type)
		if(oneCategory.length > 0){
			oneCategory.splice(0,oneCategory.length)
		}
		oneCategory.push(...res)
	}
	const getTwoCategory = async (type:'1'|'2',id:number) => {
		let res = await getGoodsTwoCategory(type,id)
		if(twoCategory.length > 0){
			twoCategory.splice(0,twoCategory.length)
		}
		threeCategory.splice(0)
		twoCategory.push(...res)
	}
	const getThreeCategory = async (type:'1'|'2',id:number) => {
		let res = await getGoodsThreeCategory(type,id)
		if(threeCategory.length > 0){
			threeCategory.splice(0,threeCategory.length)
		}
		threeCategory.push(...res)
	}
	return {
		oneCategory,
		twoCategory,
		threeCategory,
		getOneCategory,
		getTwoCategory,
		getThreeCategory
	}
}