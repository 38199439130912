/*消息提示*/
import { toastApp } from '@/common/utils/toast'
const MsgTip = function (bodyObj) {
    let code=bodyObj.code;
    switch(code){
        case 1:
            //登录失效
            this.socket?.close().then(res => {
            	this.params.onQuit?.()
            })
            break;
        case  2:
        case 99:
            toastApp(bodyObj.msg);
            break;  
    }
};
export default MsgTip;