export type LoadingIcon = {
	show?: boolean,
	color?: string,
	textColor?: string,
	vertical?: boolean,
	mode?: string,
	size?: number,
	textSize?: number,
	text?: string,
	timingFunction?: string,
	duration?: number,
	inactiveColor?: string,
	customStyle?: Record<string,any>|string,
	customClass?:string

};
export type LoadingIconPropsType = LoadingIcon
const loadingIcon: LoadingIcon = {
	// loading-icon组件
	show: true,
	color: '#909399',
	textColor: '#909399',
	vertical: false,
	mode: 'spinner',
	size: 24,
	textSize: 15,
	text: '',
	timingFunction: 'ease-in-out',
	duration: 1200,
	inactiveColor: ''
};
export default loadingIcon;
