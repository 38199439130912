import { OpenTypeProps } from './types'
type Actions = {
	name?:string,
	subname?:string,
	loading?:boolean,
	disabled?:boolean,
	color?:string,
	fontSize?:string|number,
}&OpenTypeProps

export type ActionSheet = {
  title?: string;
  round?:string|number,
  description?: string;
  actions?: Actions[];
  cancelText?: string;
  gapColor?:string,
  gapHeight?:number|string,
  closeOnClickAction?: boolean;
  closeOnClickOverlay?: boolean;
  safeAreaInsetBottom?: boolean;
  inactiveColor?: string;
  iconColor?: string;
  iconSize?: string | number;
  label?: string;
  labelSize?: string | number;
  labelColor?: string;
  labelDisabled?: boolean;
  checked?: boolean;
  customStyle?: Record<string,any>|string
};
export type ActionSheetPropsType = ActionSheet
const actionSheet: ActionSheet = {
  // actionSheet组件
  actions: [],
  gapColor:'#eaeaec',
  gapHeight:6
};
export default actionSheet;
