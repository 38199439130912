export type Row = {
  gutter?: number|string,
  justify: 'start'|'flex-start'|'end'|'flex-end'|'center'|'around'|'space-around'|'between'|'space-between',
  align: 'center'|'top'|'bottom'
  customStyle?: Record<string,any>|string,
  customClass?:string
};
export type RowPropsType = Omit<Row, "justify"> & {
  justify?: string;
  align?: string;
};
const row: Row = {
	gutter: 0,
	justify: 'start',
	align: 'center'
};
export default row;
