
export type StatusBar = {
	bgColor?: string,	
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type StatusBarPropsType = StatusBar;
const statusBar: StatusBar = {
	// statusBar组件
	bgColor:'transparent'
};
export default statusBar;
