
export type CellGroup = {
	title?: string,
	border?: boolean,
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type CellGroupPropsType = CellGroup;
const cellGroup: CellGroup = {
	// cellGroup组件
	border: true,
};
export default cellGroup;
