// 借鉴了axios的InterceptorManager部分的源码，转换为class的写法
import type { RequestExample } from "../types";
type HandlersValue<T> = {
  fulfilled: RequestExample<T>;
  rejected: RequestExample<T>;
};
let handlersKey = 0;
export default class InterceptorManager<T> {
  handlers: Map<number, HandlersValue<T>> = new Map([]);
  use(
    fulfilled: RequestExample<T>,
    rejected: RequestExample<T> = (config) => config
  ) {
    this.handlers.set(handlersKey, {
      fulfilled,
      rejected,
    });
    handlersKey++;
    return handlersKey;
  }
  eject(key: number) {
    if (this.handlers.has(key)) {
      this.handlers.delete(key);
    }
  }
  [Symbol.iterator]() {
    var mapIter = this.handlers.values();
    let index = 0;
    return {
      next: () => {
        if (index < this.handlers.size) {
          let value = mapIter.next().value as HandlersValue<T>;
          index++;
          return { value, done: index > this.handlers.size };
        } else {
          return { value: undefined, done: true };
        }
      },
      return() {
        return { value: undefined, done: true };
      },
    };
  }
}
