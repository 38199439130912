export type SwiperIndicator = {
	length?: number,
	current?: number,
	indicatorActiveColor?: string,
	indicatorInactiveColor?: string,
	indicatorMode?: 'line'|'dot'
};
export type SwiperIndicatorPropsType = Omit<
	SwiperIndicator,
	"indicatorMode"
> & {
	indicatorMode?:string
};
const swiperIndicator: SwiperIndicator = {
	// swiper-indicator组件
	    length: 0,
	    current: 0,
	    indicatorActiveColor: '',
	    indicatorInactiveColor: '',
	    indicatorMode: 'line'

};
export default swiperIndicator;
