import { ref,unref } from 'vue';
import { defineStore } from 'pinia'

export type goodsSkuItem = {
	name:string,
	value:{name:string,id:number}[]
}
export type skusItem = {
	id:string,
	cost?:number,
	price?:number,
	stock?:number,
	weight?:number
}

export const useGoods = defineStore('goods', () => {
	const goodsSku = ref(new Map([]))
	const goodsDescription = ref('')
	const setGoodsSku = (key:string,value:{list:goodsSkuItem[],skus:skusItem[]}) => {		
		goodsSku.value.set(key,value)
	}
	const getGoodsSku = (key:string) => {
		if(goodsSku.value.has(key)){
			return unref(goodsSku.value.get(key))
		}
		return {list:[],skus:[]}
	}
	const delGoodsSku = (key:string) => {
		if(goodsSku.value.has(key)){
			goodsSku.value.delete(key)
		}
	}
	const setGoodsDescription = (v:string) => {
		goodsDescription.value = v
	}
	const getGoodsDescription = () => goodsDescription.value
	return {
		goodsSku,
		setGoodsSku,
		getGoodsSku,
		delGoodsSku,
		setGoodsDescription,
		getGoodsDescription
	}
})