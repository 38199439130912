import App from './App.vue'
import util from '@/common/globalProperties'
import { createSSRApp } from 'vue'
import * as Pinia from 'pinia';
import endlessUi from '@/uni_modules/endless-ui'
import { i18nInit, baseInit } from '@/common/init'
import { setRequestHeader } from '@/common/request'
import { APP_TENANT, THEME_PRIMARY } from '@/env'

uni.setStorageSync('tenant', APP_TENANT)

// #ifdef H5
const hrefs = location.href.split('tenant=')
const tenants = hrefs[1] ? hrefs[1].split('&') :[] 
if(tenants[0]){
	uni.setStorageSync('tenant', tenants[0])
}
// #endif

// 设置全局请求头，所有http请求必须在此函数后执行
setRequestHeader()
baseInit();

export function createApp() {
	const app = createSSRApp(App)
	app.use(endlessUi, {
		badge: {
			absolute: true
		},
		picker: {
			confirmColor: THEME_PRIMARY
		},
		datetimePicker: {
			confirmColor: THEME_PRIMARY
		},
		modal: {
			confirmColor: THEME_PRIMARY
		},
		tabs: {
			lineColor: THEME_PRIMARY
		},
		radioGroup: {
			activeColor: THEME_PRIMARY
		}
	});
	i18nInit(app)
	app.use(Pinia.createPinia());
	util.install(app.config)
	return {
		app,
		Pinia
	}
}