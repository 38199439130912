import type { RequestConfig } from "../types";
/**
 * 默认的全局配置
 */
const requestConfig: RequestConfig<any> = {
  baseURL: "",
  header: {},
  method: "GET",
  dataType: "json",
  // #ifndef MP-ALIPAY
  responseType: "text",
  // #endif
  // #ifdef H5 || APP-PLUS || MP-ALIPAY || MP-WEIXIN
  timeout: 60000,
  // #endif
  // #ifdef APP-PLUS
  sslVerify: true,
  // #endif
  // #ifdef H5
  withCredentials: false,
  // #endif
  // #ifdef APP-PLUS
  firstIpv4: false,
  // #endif
  validateStatus: (status: number) => status >= 200 && status < 300,
  modifyData: <T>(request: Promise<T>): Promise<T> =>
    new Promise((resolve, reject) => {
      request.then(resolve).catch(reject);
    }),
	custom:{}
};

export { requestConfig };
