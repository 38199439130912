export type RadioGroup = {
  modelValue?: boolean | string | number;
  disabled?: boolean;
  shape?: "square" | "circle";
  activeColor?: string;
  inactiveColor?: string;
  name?: string;
  size?: string | number;
  placement?: "row" | "column";
  label?:string,
  labelSize?: string | number;
  labelColor?: string;
  labelDisabled?: boolean;
  iconColor?: string;
  iconSize?: string | number;
  borderBottom?: boolean;
  iconPlacement?: "left" | "right";
};
export type RadioGroupPropsType = Omit<
  RadioGroup,
  "shape" | "placement" | "iconPlacement"
> & {
  shape?: string;
  placement?: string;
  iconPlacement?: string;
};
// checkbox-group组件
const radioGroup: RadioGroup = {
  shape: "circle",
  disabled: false,
  activeColor: "#2979ff",
  inactiveColor: "#c8c9cc",
  size: 18,
  placement: "row",
  labelSize: 14,
  labelColor: "#303133",
  labelDisabled: false,
  iconColor: "#ffffff",
  iconSize: 12,
  iconPlacement: "left",
  borderBottom: false,
};
export default radioGroup;
