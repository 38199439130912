/* 会话详情 */
const WxChatItemInfoResp = function (bodyObj) {
    // state.chatinfo=res.data;
    // let storeWs=store.state.Ws;
    // let {chatOn,chatList,islastmsg}=storeWs;
    // let chatlinkid=bodyObj.chatlinkid;
    // bodyObj.data.avatar=resUrl(bodyObj.data.avatar);
    // if(islastmsg){
    //     store.commit("setIsLastMsg",false);
    //     let delchatInfo=chatList.find(item=>item.id==chatlinkid);
    //     if(delchatInfo){
    //         let data=bodyObj.data;
    //         Object.assign(delchatInfo,{msgresume:data.msgresume,toreadflag:data.toreadflag,lastmsgid:data.lastmsgid});
    //     }
    // }
    
    // if(chatlinkid!=chatOn){
    //     return;
    // }
    // store.commit('setChatInfo',bodyObj.data);
};
export default WxChatItemInfoResp;