/*朋友聊天通知-- Server-->Client*/
import msgStorage from '../msgStorage'
const WxFriendChatNtf = async function (bodyObj) {
    


    let bodytouid=bodyObj.touid,//接收方userid
        bodyuid=bodyObj.uid;//发送方userid
   msgStorage.saveMsg(bodyObj)
};
export default WxFriendChatNtf;
