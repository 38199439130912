export default {
	empty: '暂无优惠券',
	type1: '满',
	type2: '无门槛',
	sub: '减',
	state1: '未开始',
	state2: '进行中',
	state3: '已过期',
	timeState1: '领券后立即生效',
	timeState2: '有效期',
	txt1: '剩余：',
	txt2: '已领：',
	txt3: '打',
	txt4: '折',
	txt5: '最多优惠',
	invalidTips: '确定要失效该优惠券吗？',
	editTitle: '编辑优惠券',
	addTitle: '添加优惠券',
	label1: '优惠券类型',
	label2: '优惠券名称',
	label3: '适用商品',
	label4: '使用门槛',
	label5: '订单满',
	label6: '，使用',
	label7: '满减金额',
	label8: '发放数量',
	label9: '张',
	label10: '领券时间',
	label11: '用券时间',
	label12: '限领次数',
	label13: '次',
	label14: '优惠折扣',
	label15: '限领身份',
	label16: '所有会员可领',
	label17: '指定身份',
	label18: '满减券',
	label19: '折扣券',
	label20: '已编辑',
	label21: '全部商品可用',
	label22: '指定分类可用',
	label23: '指定商品可用',
	label24: '优惠折扣',
	formTips1: '请输入最多12字符的标题',
	formTips2: '请选择适用商品',
	formTips3: '请输入使用门槛',
	formTips4: '请输入满减金额',
	formTips5: '请输入发放数量',
	formTips6: '请选择开始时间',
	formTips7: '请选择结束时间',
	formTips8: '请输入限领次数',
	// formTips9: '请输入限领次数',
	// formTips10: '请输入优惠折扣',
	// formTips11: '请选择可领取的身份',
	release: "确定添加",
	addClassify: '添加分类',
	addGoods: '添加商品',
	select: '选择分类',
	selectTips: '请选择',
	userSearchTips: '请输入用户ID搜索',
	userEmpty: '暂无人领取优惠券',
	dataSearchTips: '请输入订单编号/用户ID搜索',
	dataEmpty: '暂无人使用优惠券',

}