export default {
	Ended:'已结束',
	emptySeckill:'暂无秒杀活动',
	activityTime:'活动时间',
	inputTitle:'请输入标题',
	selectBeginTime:'请选择活动开始时间',
	selectEndTime:'请选择活动结束时间',
	close:'关闭',
	open:'开启',
	searchByTitle:'请输入活动名称搜索',
	orderNum:'订单数',
	payMoney:'实付金额',
	closed:'已关闭',
	addSeckill:'添加秒杀',
	disabled:'已禁用',
	searchByGoodsName:'请输入商品名称搜索',
	seckillData:'秒杀数据',
	seckillDetail:'秒杀详情',
	seckillMoney:'秒杀收益',
	seckillOrder:'秒杀订单',
	seckillAllSale:'总销量',
	seckillBuyer:'购买人数',
	editGoods:'编辑商品',
	goodsManage:'商品管理',
	addProduct:'添加商品',
	emptySeckillSku:'暂无秒杀商品',
	selectProduct:'选择商品',
	seckillPrice:'秒杀价',
	seckillJoinStock:'参与库存',
	oldPrice:'原价',
	stock:'库存',
	pleaseEnterprice:'请输入秒杀价',
	plaseSelect:'请选择商品规格',
	product:'商品'
}