import type {ImageModeTypes} from './types'
export type Avatar = {
	src?: string;
	shape?: 'circle' | 'square';
	size?: string | number;
	mode?: ImageModeTypes;
	text?: string;
	bgColor?: string;
	color?: string;
	fontSize?: string | number;
	icon?: string;
	mpAvatar?: boolean;
	randomBgColor?: boolean;
	defaultUrl?: string;
	colorIndex?: string | number;
	name?: string;
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type AvatarPropsType = Omit<
	Avatar,
	"shape"|"mode"
> & {
	shape?: string;
	mode?:string
};
const avatar: Avatar = {
	// avatar组件
	shape: 'circle',
	size: 40,
	mode: 'scaleToFill',
	bgColor: '#c0c4cc',
	color: '#ffffff',
	fontSize: 18,
	mpAvatar: false,
	randomBgColor: false,

};
export default avatar;
