import { accAdd, accMul, accDiv,fullLink } from "@/common/utils";
import { timeFormat } from "@/uni_modules/endless-ui/libs/utils/lodash/date";
import { SelectDictLabel } from './type'
import type { AppConfig } from "vue";

const selectDictLabel: SelectDictLabel = (datas, value) => {
	let actions: string[] = [];
	datas.some((item) => {
		
		if (item.dictValue === `${value}`) {
			actions.push(item.dictLabel);
			return true;
		}
	});
	return actions.join("");
};


export default {
	init({ globalProperties }: AppConfig) {
		globalProperties.$timeFormat = timeFormat;
		// 回显数据字典
		globalProperties.$selectDictLabel = selectDictLabel
		/**
		 * H5 复制
		 * @auhor LiJun
		 * @method $copy
		 * @for mkUtil
		 * @param {content} 需要复制的内容
		 * @example  无返回值
		 * @return {} 无返回值
		 */
		globalProperties.$copy = function(
			content: string,
			backCall: (() => void) | boolean = true
		) {
			// #ifndef H5
			uni.setClipboardData({
				data: content,
			});
			// #endif
			// #ifdef H5
			// 数字没有 .length 不能执行selectText 需要转化成字符串
			const textString = content.toString();
			let input = document.querySelector("#copy-input");
			if (!input) {
				input = document.createElement("input");
				input.id = "copy-input";
				input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
				input.style.position = "fixed";
				input.style.left = "-1000px";
				input.style.zIndex = "-1000";
				document.body.appendChild(input);
			}
			input.value = textString;
			// ios必须先选中文字且不支持 input.select();
			selectText(input, 0, textString.length);
			// console.log(document.execCommand('copy'), 'execCommand');
			if (document.execCommand("copy")) {
				document.execCommand("copy");
				if (typeof backCall === "function") {
					backCall();
				} else {
					this.$toast(this.$t("common.copySuccess"));
				}
			}
			input.blur();
			// input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
			// 选择文本。createTextRange(setSelectionRange)是input方法
			function selectText(textbox, startIndex, stopIndex) {
				if (textbox.createTextRange) {
					//ie
					const range = textbox.createTextRange();
					range.collapse(true);
					range.moveStart("character", startIndex); //起始光标
					range.moveEnd("character", stopIndex - startIndex); //结束光标
					range.select(); //不兼容苹果
				} else {
					//firefox/chrome
					textbox.setSelectionRange(startIndex, stopIndex);
					textbox.focus();
				}
			}
			// #endif
		};
		globalProperties.$accMul = accMul;

		globalProperties.$accAdd = accAdd;

		globalProperties.$accDiv = accDiv;
		globalProperties.$fullLink = function (url:string) {
			return fullLink( this.$pinia.state.value?.global?.global?.host,url)
		}
	},
};
