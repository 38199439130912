export default {
	confirm:'确认',
	completed:'完成',
	back:'返回',
	year:'年',
	month:'月',
	day:'日',
	tips:'提示',
	payPawTips:'请输入支付密码',
	forgotPaw:'忘记密码？',
	all:'全部',
	del:'删除',
	cancel:'取消',
	determine:'确定',
	placeholder:'请输入',
	edit:'编辑',
	save:'保存',
	copy:'复制',
	copySuccess:'已复制到粘贴板',
	submit:'提交',
	common:'共',
	piece:'件',
	createTime:'创建时间',
	number:'数量',
	search:'搜索',
	colon:'：',
	remark:'备注',
	sun:"周日",
	mon:"周一",
	tue:"周二",
	wed:"周三",
	thu:"周四",
	fri:"周五",
	sat:"周六",
	today:"今天",
	tomorrow:"明天",
	filter:'筛选',
	startDate:'开始日期',
	endDate:'结束日期',
	reset:'重置',
	to:'至',
	saveSuccess:'保存成功',
	confirmDel:'确定要删除吗',
	operateSuccess:'操作成功',
	pleaseSelect:'请选择'
}