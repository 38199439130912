import { getCurrentInstance, ComponentInternalInstance, ComponentPublicInstance } from "vue";
import type {
	ToastApp,
	Toast,
	ShowModal,
	ShowAsyncModal,
	RouteJump,
	BackFn,
	CopyFn,
	calculateFn,
	SelectDictLabel
} from "@/common/globalProperties/type";
import type { TimeFormat } from "@/uni_modules/endless-ui/libs/utils/lodash/date";

export interface VueProxy {
	$toastApp: ToastApp;
	$toast: Toast;
	$showModal: ShowModal;
	$showAsyncModal:ShowAsyncModal;
	$goTo: RouteJump;
	$goRedirectTo: RouteJump;
	$back: BackFn;
	$copy: CopyFn;
	$accMul: calculateFn;
	$accAdd: calculateFn;
	$accDiv: calculateFn;
	$timeFormat: TimeFormat;
	$selectDictLabel:SelectDictLabel
	$fullLink:(url:string) => string
	$t:(t:string) => string
}
type Proxy = ComponentPublicInstance & VueProxy

export const useCurrentInstance = () => {
	const { proxy } = <ComponentInternalInstance>getCurrentInstance();
	// 返回全局上下文，并将上下文断言为非空
	return proxy! as Proxy;
};
