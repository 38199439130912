import type {Badge} from './badge'
type tabItem = {
	disabled?:boolean,
	badge?:Badge
}&Record<any,any>

export type Tabs = {
	duration?:number,
	list?: tabItem[],
	lineColor?: string,
	activeStyle?: Record<any,any>,
	inactiveStyle?: Record<any,any>,
	lineWidth?: number,
	lineHeight?: number,
	itemStyle?: Record<any,any>,
	itemActiveStyle?: Record<any,any>,
	scrollable?: boolean,
	current?: number,
	keyName?: string
};
export type TabsPropsType = Tabs
const tabs: Tabs = {
	// tabs组件
	duration: 300,
	list: [],
	lineColor: '#3c9cff',
	activeStyle: {
		color: '#303133'
	},
	inactiveStyle: {
		color: '#606266'
	},
	lineWidth: 20,
	lineHeight: 3,
	itemStyle: {
		height: '44px'
	},
	scrollable: true,
	current: 0,
	keyName: 'name'

};
export default tabs;
