
export type CountDown = {
	time?: number|string
	format?:string
	autoStart?: boolean
	millisecond?: boolean
};
export type CountDownPropsType = CountDown;
const countDown: CountDown = {
	// countDown组件
	time: 0,
	format: 'HH:mm:ss',
	autoStart: true,
	millisecond: false

};
export default countDown; 
 
