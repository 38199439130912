// #ifdef  APP-PLUS||MP-WEIXIN
export class TextDecoder {
	decode(arrayBuffer : Uint8Array) {
			return decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))));
		}
}

export class TextEncoder {
	encode(str : string) {
			var data = unescape(encodeURIComponent(str))
				.split('')
				.map(val => val.charCodeAt(0));
			return typeof Uint8Array == 'function' ? new Uint8Array(data) : data;
		}
}
// #endif

export const getIMUserId = ():number => uni.getStorageSync('IMUserInfo')?.id