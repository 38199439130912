export default {
  tabTxt1: "未払い",
  tabTxt2: "未出荷",
  tabTxt3: "未受取",
  tabTxt4: "評価待ち",
  tabTxt5: "返品/修理",
  tabTxt6: "返品申請中",
  tabTxt7: "返品成功",
  tabTxt8: "返品失敗",
  empty: "注文なし",
  orderId: "注文番号",
  refundId: "返品番号",
  paid: "支払額",
  toBePaidTxt:
    "お客様の支払い待ちです。25分以上経過すると、注文は自動的にキャンセルされます。",
  addressTxt1: "配送先住所",
  addressTxt2: "受取人",
  goodsList: "商品リスト",
  open: "もっと見る",
  stow: "隠す",
  label1: "送料",
  label2: "割引済",
  label3: "総計",
  label4: "注文備考",
  label5: "注文番号",
  label6: "注文日時",
  label7: "支払方法",
  label8: "返品理由",
  label9: "返品金額",
  label10: "返品申請日時",
  label11: "クーポン金額",
  label12: "控除額",
  orderInfo: "注文情報",
  refundInfo: "返品情報",
  delivery: "発送",
  deliveryTxt1: "注文の発送",
  deliveryTxt2: "発送方法",
  deliveryTxt3: "物流発送",
  deliveryTxt4: "物流不要",
  deliveryTxt5: "物流番号",
  deliveryTxt6: "バーコードをスキャン",
  deliveryTxt7: "物流会社",
  deliveryTxt8: "物流会社を選択してください",
  deliveryTxt9: "バーコードを入力してください",
  info: "詳細情報",
  agree: "同意する",
  agreeTips: "返品/修理に同意しますか？",
  refuse: "拒否する",
  refuseTips: "返品/修理を拒否しますか？",
  refuseTips1: "拒否理由を入力してください",
  refuseTxt: "拒否理由",
  receipt: "受取確認",
  receiptTips: "受取を確認しますか？",
  reject: "受取拒否",
  rejectTips: "受取を拒否しますか？",
  refund: "返金",
  refundTips: "返金に同意しますか？",
  payment: "支払い確認",
  paymentTips: "お客様に支払いを行いますか？",
  refundGoods: "返品商品",
  evaluation: "ストア評価",
  reply: "返信",
  checkCodeTips: "検証コードを入力してください",
  checkCodeTxt1: "検証を確認",
  checkCodeTxt2: "QRコードをスキャン",
  checkCodeSuccess: "検証成功",
};
