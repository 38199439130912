
import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { TiocomConfig, StoreDetail, IMUserInfo, HistoryChatMsg } from '@/common/request/api'
import { getIMConfig, postIMLogin, getIMUser, getIMChatList } from '@/common/request/api'
import { setIMUrl, IMInit } from '@/common/utils/IM/init'
import type {
	ReceiveMsg
} from '@/common/utils/IM'
import { AppCookie, timeFormat } from '@/common/utils'

export const useIM = defineStore('IM', () => {
	// IM配置项
	const tiocomConfig = ref<TiocomConfig>(uni.getStorageSync('IMConfig') || {
		session_cookie_name: '',
		res_server: ''
	})

	// 用户资料
	const IMUserInfo = ref<IMUserInfo>(uni.getStorageSync('IMUserInfo') ? uni.getStorageSync('IMUserInfo') : {})

	const chatMsgList = ref<HistoryChatMsg[]>([])


	const getChatMsgList = () => {
		getIMChatList().then(res => {
			chatMsgList.value = res.data
		})
	}

	const notreadcount = computed(() => {
		return chatMsgList.value.reduce((a, b) => a + b.notreadcount, 0)
	})

	const setChatMsgNotreadcount = (index : number) => {
		const item = chatMsgList.value[index]
		item.notreadcount = 0
		chatMsgList.value.splice(index, 1, item)
	}

	const updateReadMessage = ({ curChatMsg, bodyuid } : { curChatMsg : ReceiveMsg, bodyuid : string }) => {


		const index = chatMsgList.value.findIndex(item => item.bizid === bodyuid)
		if (index > -1) {
			const item = chatMsgList.value[index]
			item.sendtime = timeFormat(new Date, 'yyyy-mm-dd hh:MM:ss')
			item.msgtype = curChatMsg.ct
			item.msgresume = curChatMsg.c || ''
			item.notreadcount++
			console.log(item)
			chatMsgList.value.splice(index, 1, item)
		}


	}

	// 添加监听
	uni.$on('updateReadMessageNum', updateReadMessage)

	const getConfig = async () => {
		if (!tiocomConfig.value.res_server) {
			const res = await getIMConfig()
			uni.setStorageSync('IMConfig', res.data)
			tiocomConfig.value = res.data
		}
		AppCookie.init()

	}

	const _IMLogin = (info : StoreDetail) => {
		getConfig().then(async () => {
			if(!AppCookie.get(tiocomConfig.value.session_cookie_name)){
				await postIMLogin(info.imInfo)
			}
			AppCookie.init()
			getIMUser().then(res => {
				IMUserInfo.value = res.data
				uni.setStorageSync('IMUserInfo', res.data)

			})
			setIMUrl(tiocomConfig.value).then(() => {
				IMInit()
				// IMLogin()
			})
			getChatMsgList()
		})

	}
	const channelContextid = ref('')
	const setChannelContextid = (v : string) => {
		channelContextid.value = v
	}

	return {
		tiocomConfig,
		getConfig,
		chatMsgList,
		notreadcount,
		setChatMsgNotreadcount,
		IMLogin: _IMLogin,
		channelContextid,
		setChannelContextid
	}
})