export default {
	tabTxt1:'待付款',
	tabTxt2:'待发货',
	tabTxt3:'待收货',
	tabTxt4:'待评价',
	tabTxt5:'退款/售后',
	tabTxt6:'退款中',
	tabTxt7:'退款成功',
	tabTxt8:'退款失败',
	empty:'暂无订单',
	orderId:'订单编号',
	refundId:'售后编号',
	paid:'实付',
	toBePaidTxt:'等待客户支付，超过25分钟未支付，系统将自动取消',
	addressTxt1:'收货地址',
	addressTxt2:'收货人',
	goodsList:'商品列表',
	open:'展开更多',
	stow:'点击收起',
	label1:'运费',
	label2:'已优惠 ',
	label3:'合计',
	label4:'订单备注',
	label5:'订单编号',
	label6:'下单时间',
	label7:'支付方式',
	label8:'退款原因',
	label9:'退款金额',
	label10:'申请时间',
	label11:'优惠劵金额',
	label12:'抵扣金额',
	orderInfo:'订单信息',
	refundInfo:'退款信息',
	delivery:'发货',
	deliveryTxt1:'订单发货',
	deliveryTxt2:'发货方式',
	deliveryTxt3:'物流发货',
	deliveryTxt4:'无需物流',
	deliveryTxt5:'物流编号',
	deliveryTxt6:'扫一扫条形码',
	deliveryTxt7:'物流公司',
	deliveryTxt8:'请选择物流公司',
	deliveryTxt9:'请填写条形码',
	info:'详细信息',
	agree:'同意',
	agreeTips:'确定同意退货退款？',
	refuse:'拒绝',
	refuseTips:'确定拒绝退货退款？',
	refuseTips1:'请输入拒绝原因',
	refuseTxt:'拒绝原因',
	receipt:'确认收货',
	receiptTips:'确定确认收货吗？',
	reject:'拒绝收货',
	rejectTips:'确定拒绝收货？',
	refund:'退款',
	refundTips:'确定同意退款？',
	payment:'确认打款',
	paymentTips:'确定打款给用户吗？',
	refundGoods:'售后商品',
	evaluation:'店铺评分',
	reply:'回复',
	evaluationTabTxt1:'未回复',
	evaluationTabTxt2:'差评',
	checkCodeTips:'请输入核销码',
	checkCodeTxt1:'确认核销',
	checkCodeTxt2:'扫码核销',
	checkCodeSuccess:'核销成功',
	// checkCodeHistoryEmpty:'暂无核销记录',
	// statusTxt1:'已完成',
	// statusTxt2:'待评价',
	// statusTxt3:'待使用',
	allEval:'全部评价',
	evalWithPic:'带图评价'
	
}