export type Qrcode = {
  mode?: 'canvas'|'view',
  text?: string,
  size?: number,
  margin?: number,
  backgroundColor?: string,
  foregroundColor?: string,
  errorCorrectLevel?:number,
  fileType?: 'png'|'jpg'
};
export type QrcodePropsType = Omit<Qrcode, "mode"|"fileType"> & {
  mode?: string;
  fileType?:string
};
const qrcode: Qrcode = {
	// qrcode 组件
	mode: 'canvas',
	size: 256,
	margin: 0,
	backgroundColor: '#FFFFFF',
	foregroundColor: '#000000',
	errorCorrectLevel:2,
	fileType: 'png'
};
export default qrcode;
