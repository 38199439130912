type CB = (...args:any[])=> void

export  class dispatcher {
	protected dispCbs:Record<string,Function[]> = {}
	on(type:string, cb:CB) {
	  let cbs = this.dispCbs[type] = this.dispCbs[type] || [];
	  if (!~cbs.indexOf(cb)) {
			cbs.push(cb);
	  }
	}
	off(type:string, cb:CB) {
	  let cbs = this.dispCbs[type] = this.dispCbs[type] || [];
	  let curTypeCbIdx = cbs.indexOf(cb);
	  if (~curTypeCbIdx) {
	    cbs.splice(curTypeCbIdx, 1);
	  }
	}
	fire(type:string, ...args:any[]) {
	 let cbs = this.dispCbs[type] = this.dispCbs[type] || [];
	
	  for (let i = 0; i < cbs.length; i++) {
	    cbs[i].apply(null, args);
	  }
	}
}

// export default new dispatcher()