export default {
  headerTxt1: "私の資産",
  headerTxt2: "財布残高",
  headerTxt3: "凍結残高：",
  extract: "引き出し",
  bankCardManage: "銀行カード管理",
  extractHistory: "引き出し記録",
  moneyInfo: "資金詳細",
  empty: "明細がありません",
};
