/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const debounce = (fn: Function, delay: number = 500): (() => void) => {
  let timer: number | undefined;
  // console.log(fn)
  // console.log(typeof fn)
  return function (this: any) {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = undefined;
      fn.apply(this, args);
    }, delay);
  };
};
/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
export const throttle = (
  fn: Function,
  interval: number = 500
): (() => void) => {
  let last: number;
  let timer: number | undefined;
  return function (this: any) {
    let args: IArguments = arguments;
    let now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
};

/**
 * 进行延时，以达到可以简写代码的目的，比如
 * await sleep(20)将会阻塞20ms
 */
export const sleep = (value = 30) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, value);
  });


/**
 * @description 获取当前页面路径
 */
export const  page = () => {
	const pages = getCurrentPages()
	// 某些特殊情况下(比如页面进行redirectTo时的一些时机)，pages可能为空数组
	return `/${pages[pages.length - 1]?.route ?? ''}`
}

/**
 * @description 运行期判断平台
 * @returns {string} 返回所在平台(小写)
 * @link 运行期判断平台 https://uniapp.dcloud.io/frame?id=判断平台
 */
export const os = ():string => {
  return sys().platform.toLowerCase();
};
/**
 * @description 获取系统信息同步接口
 * @link 获取系统信息同步接口 https://uniapp.dcloud.io/api/system/info?id=getsysteminfosync
 */
export const sys = () => {
  return uni.getSystemInfoSync();
};
