export type TabbarItem = {
	name?: string|number,
	icon?: string,
	badge?: string|number,
	dot?: boolean,
	text?: string,
	badgeStyle?: string|Record<string,any>
};
export type TabbarItemPropsType = TabbarItem
const tabbarItem: TabbarItem = {
	// tabbarItem组件
	dot: false,
	badgeStyle: 'top: 6px;right:2px;'

};
export default tabbarItem;
