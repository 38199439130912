// export type ZIndex = {
// 	toast?: string | number,
// 	noNetwork?: string | number,
// 	popup?: string | number,
// 	mask?: string | number,
// 	navbar?: string | number,
// 	topTips?: string | number,
// 	sticky?: string | number,
// 	indexListSticky?: string | number
// };
// export type ZIndexPropsType = ZIndex
const zIndex = {
	// toast: 10090,
	// noNetwork: 10080,
	skeleton:10080,
	// popup包含popup，actionsheet，keyboard，picker的值
	popup: 10075,
	overlay: 10070,
	loadingPage:990,
	skeletonNavbar: 985,
	navbar: 980,
	// topTips: 975,
	sticky: 970,
	skeletonTabbar:965,
	tabbar:960,
	select:950


};
export default zIndex;
