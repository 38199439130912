import MsgTip from './MsgTip';//服务器消息通知
import WxHandshakeResp from './WxHandshakeResp';

import WxFriendChatNtf from './WxFriendChatNtf';//朋友聊天通知
// import WxGroupChatNtf from './WxGroupChatNtf';

// import WxLeaveGroupNtf from './WxLeaveGroupNtf';
// import WxNotFriendNtf from './WxNotFriendNtf';
// import WxUserOperNtf from './WxUserOperNtf';//操作通知
import WxFriendErrorNtf from './WxFriendErrorNtf';//异常通知
// import WxGroupOperNtf from "./WxGroupOperNtf";//群聊操作通知
// import WxUserSysNtf from './WxUserSysNtf';//用户系统通知
import WxFriendMsgResp from './WxFriendMsgResp';//私聊历史通知
// import WxGroupMsgResp from './WxGroupMsgResp';//群聊历史通知
import WxChatItemInfoResp from './WxChatItemInfoResp';//会话详情
import WxFocusNtf from './WxFocusNtf';//进入某个会话
// import WxCall00Start from './WxCall00Start';
// import WxCall02Ntf from './WxCall02Ntf';
// import WxCall02_2CancelNtf from './WxCall02_2CancelNtf';
// import WxCall04ReplyNtf from './WxCall04ReplyNtf';
// import WxCall06OfferSdpNtf from './WxCall06OfferSdpNtf';
// import WxCall08AnswerSdpNtf from './WxCall08AnswerSdpNtf';
// import WxCall10OfferIceNtf from './WxCall10OfferIceNtf';
// import WxCall12AnswerIceNtf from './WxCall12AnswerIceNtf';
// import WxCall14EndNtf from './WxCall14EndNtf';
// import WxCallRespNtf from './WxCallRespNtf';
type Fn = (data:any) => void
export const wsBs:Record<string,Fn> = {
	MsgTip,
	WxHandshakeResp,
	WxChatItemInfoResp,
	WxFocusNtf,
	WxFriendMsgResp,
	WxFriendErrorNtf,
	WxFriendChatNtf
}

// export  {
//     MsgTip,
//     WxFriendChatNtf,
//     WxGroupChatNtf,
//     WxHandshakeResp,
//     WxLeaveGroupNtf,
//     WxNotFriendNtf,
//     WxUserOperNtf,
//     WxFriendErrorNtf,
//     WxGroupOperNtf,
//     WxUserSysNtf,
//     WxFriendMsgResp,
//     WxGroupMsgResp,
//     WxChatItemInfoResp,
//     WxFocusNtf,
//     WxCall00Start,
//     WxCall02Ntf,
//     WxCall02_2CancelNtf,
//     WxCall04ReplyNtf,
//     WxCall06OfferSdpNtf,
//     WxCall08AnswerSdpNtf,
//     WxCall10OfferIceNtf,
//     WxCall12AnswerIceNtf,
//     WxCall14EndNtf,
//     WxCallRespNtf,
// }








