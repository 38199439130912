import {AlignTypes} from '../props'
export type Col = {
  span?: number|string,
  offset?: number|string,
  justify: 'start'|'flex-start'|'end'|'flex-end'|'center'|'around'|'space-around'|'between'|'space-between',
  align: 'center'|'top'|'bottom'|'stretch'
  textAlign:AlignTypes
  customStyle?: Record<string,any>|string,
  customClass?:string
};
export type ColPropsType = Omit<Col, "justify"> & {
  justify?: string;
  align?: string;
};
const col: Col = {
	span: 12,
	offset: 0,
	justify: 'start',
	align: 'stretch',
	textAlign: 'left'
};
export default col;
