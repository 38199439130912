/*焦点状态机通知-- Server-->Client*/

const WxFocusNtf = function (bodyObj) {
    //处理同一用户多端进入不同会话
    // let focusMap=bodyObj.focusMap;
    // let focuskeys=Object.keys(focusMap);
    
    // let storeWs=store.state.Ws;
    // let {chatList,allNotRead,chatOn}=storeWs;
    // focuskeys.map(item=>{
    //     if(focusMap[item]==1&&chatOn!=item){
    //         let chatcol=chatList.find(v=>v.id==item);
    //         if(chatcol){
    //             if(chatcol.atreadflag==2){
    //                 chatcol.atreadflag=1;
    //             }
    //             store.commit("setAllNotRead",allNotRead-chatcol.notreadcount);//总未读条数
    //             chatcol.notreadcount=0;
               
    //         }
    //     }
    // })
};
export default WxFocusNtf;