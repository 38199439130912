import type { AppConfig } from "vue";
import { THEME_PRIMARY } from '@/env'
import { toast, toastApp } from '@/common/utils/toast'
import { ShowModal,AsyncModalParams, ModalParams } from './type'

const objToUri = (obj: Record<string, string | number>) => {
	return Object.keys(obj)
		.map(function(k) {
			return k + '=' + obj[k]
		})
		.join('&')
}

const jumpCheck = function(this: any, url: string, hasLogin = false, options = {}) {
	if (typeof hasLogin !== 'boolean') {
		[hasLogin, options] = [false, hasLogin]
	}

	if (hasLogin && !this.$pinia.state.value.user.isLogin) {
		return "/pages/public/account/login"
	}
	let goToUrl = Object.keys(options).length > 0 ? `${url}?${objToUri(options)}` : url
	return goToUrl

}

type JumpParams = [string, false, Record<string, any>]

export default {
	init({ globalProperties }: AppConfig) {
		/**
		 * 此方法在APP下使用原生消息提示
		 * @method $toastApp
		 * @for UinAppUtil
		 * @param {msg} 提示内容
		 */
		globalProperties.$toastApp = toastApp

		globalProperties.$toast = toast
		/**
		 * 弹窗提示框
		 * @method $showModal
		 * @for UinAppUtils
		 * @param  params
		 * 与uni.showModal参数一致
		 */
		globalProperties.$showModal = function(params: ModalParams) {
			if (!params.title && this.$t) {
				params.title = this.$t('common.tips')
			}
			
			params.cancelColor = params.cancelColor || '#4D4D4D',
				params.confirmColor = params.confirmColor || THEME_PRIMARY
			uni.showModal(params)
		}
		/**
		 * 异步弹窗提示框
		 * @method $showAsyncModal
		 * @for UinAppUtils
		 * @param  params
		 * 与uni.showModal参数一致
		 */
		globalProperties.$showAsyncModal = function(params: AsyncModalParams) {
			return new Promise((resolve, reject) => {
				(this.$showModal as ShowModal)({
					...params,
					success: (res) => {
						resolve(res.confirm)
					},
					fail: () => {
						reject()
					}
				})
		
			})
		
		}
		/**
		 * 页面跳转
		 * @method $goTo
		 * @for UinAppUtil
		 * @param {url, hasLogin, options}  跳转链接,是否需要登录,参数
		 */
		globalProperties.$goTo = function (...args:JumpParams) {
			// console.log('不需要登录', hasLogin);
			uni.navigateTo({
				url: jumpCheck.apply(this, args)
			})
		}
		/**
		 * 页面跳转-关闭当前
		 * @method $goRedirectTo
		 * @for UinAppUtil
		 * @param {url, hasLogin, options}  跳转链接,是否需要登录,参数
		 */
		globalProperties.$goRedirectTo = function(...args:JumpParams) {
			uni.redirectTo({
				url: jumpCheck.apply(this, args)
			})
		}
		/**
		 * 页面跳转-关闭所有页面
		 * @method $goReLaunch
		 * @for UinAppUtil
		 * @param {url, hasLogin, options}  跳转链接,是否需要登录,参数
		 */
		globalProperties.$goReLaunch = function(...args:JumpParams) {
			uni.reLaunch({
				url: jumpCheck.apply(this, args)
			})
		}
		globalProperties.$back = function(delta = 1) {
			uni.navigateBack({
				delta
			})
		}
	}
}
