import code,{ type Code} from './code'
import button,{ type Button} from './button'

export type CodeButton = Code & Button;
export type CodeButtonPropsType = CodeButton
const codeButton: CodeButton = {
	// codeButton组件
	...code,
	...button
};
export default codeButton;
