export type Collapse = {
	value?: string | number | Array<string | number>
	accordion?: boolean
	border?: boolean
};
export type CollapsePropsType = Collapse;
const collapse: Collapse = {
	// collapse组件
	value:[],
	accordion: false,
	border: true

};
export default collapse;
