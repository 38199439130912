export default {
	viewAll:'查看全部',
	empty:'暂无记录',
	noCard:'您当前还未绑定银行卡',
	noBinding:'暂未添加绑定银行卡',
	binding:'+去绑定',
	addCard:'绑定新银行卡',
	isDelCard:'确定要解除绑定此银行卡吗？',
	cardLabel1:'持卡人',
	cardLabel2:'身份证',
	cardLabel3:'开户行',
	cardLabel4:'卡号',
	cardLabel5:'手机号',
	cardTips1:'请输入持卡人姓名',
	cardTips2:'请输入身份证号码',
	cardTips3:'请输入选择开户行',
	cardTips4:'请输入银行卡卡号',
	cardTips5:'请输入银行预留手机号码',
	nowBinding:'立即绑定',
	selectCard:'选择到账银行卡',
	extractCard:'到账银行卡',
	nowExtract:'立即提现',
	allExtract:'全部提现',
	userMoney:'可提现余额',
	extractMoney:'提现金额',
	extractFee:'提现手续费',
	extractTips1:'请选择银行卡',
	extractTips2:'请输入提现金额',
	extractTips3:'最低提现金额为',
	extractTips4:'最高提现金额为',
	extractSuccessTips1:'提现成功',
	extractSuccessTips2:'提现金额将会在1-3个工作日内到账',
	extractStatus1:'审核中',
	extractStatus2:'提现成功',
	extractStatus3:'审核失败',
	income:'收入',
	expend:'支出',
	balanceDetails:'余额明细',
	frozenDetails:'冻结明细',

	
}