import { DEFAULT_LOCATION, APP_MAP_TYPE } from '@/env'
import { tGetMapKey, tLoadMapScript, tGeolocation, tAddressToLocation, tNearbyAddress } from "./tencentApi"
import { gGetMapKey, gLoadMapScript, gGeolocation, gAddressToLocation, gNearbyAddress } from "./googleApi"
import { toastApp } from '@/common/utils/toast'

const defaultLatitude = DEFAULT_LOCATION[0]
const defaultLongitude = DEFAULT_LOCATION[1]

const getMapKey = APP_MAP_TYPE === 'tencent' ? tGetMapKey : gGetMapKey

// 地图初始化
const loadMapScript = APP_MAP_TYPE === 'tencent' ? tLoadMapScript : gLoadMapScript

// 根据地址信息转换经纬度
const addressToLocation = APP_MAP_TYPE === 'tencent' ? tAddressToLocation : gAddressToLocation
// 逆地址解析
const geolocation = APP_MAP_TYPE === 'tencent' ? tGeolocation : gGeolocation
// 附近地点
const nearbyAddress = APP_MAP_TYPE === 'tencent' ? tNearbyAddress : gNearbyAddress

const getLocation = (errText1: string, errText2: string,isGeolocation = true) => {
	return new Promise((resolve, reject) => {
		// #ifdef MP-WEIXIN
		uni.getLocation({
			type: "wgs84",
			success: (res: { latitude: number; longitude: number }) => {
				let { latitude, longitude } = res;
				geolocation(latitude, longitude,isGeolocation).then(resolve).catch(reject);
			},
			fail: () => {
				console.log("获取当前位置失败");
				// 使用默认的北京坐标
				geolocation(defaultLatitude, defaultLongitude,isGeolocation)
					.then(resolve)
					.catch(reject);
			},
		});
		// #endif
		// #ifdef APP-PLUS
		plus.geolocation.getCurrentPosition(
			(res: {
				coords: {
					longitude: number;
					latitude: number;
				};
			}) => {
				console.log("当前位置的经度：" + res.coords.longitude);
				console.log("当前位置的纬度：" + res.coords.latitude);
				geolocation(res.coords.latitude, res.coords.longitude,isGeolocation)
					.then(resolve)
					.catch(reject);
			},
			(err: { code: number }) => {
				if (err.code === 1 || err.code === 2) {
					toastApp(errText1)
				} else {
					toastApp(errText2)
				}
				geolocation(defaultLatitude, defaultLongitude,isGeolocation)
					.then(resolve)
					.catch(reject);
			},
			{
				timeout: 10000,
				provider: "system",
				geocode: false,
			}
		);
		// #endif
	});
};


export { getMapKey, loadMapScript, geolocation, getLocation, defaultLatitude, defaultLongitude, addressToLocation, nearbyAddress }
