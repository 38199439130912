import { ref } from 'vue';
import { defineStore } from 'pinia'
import type { Locale } from '@/common/init/i18n'

export type BaseVariable = {
	unitEn: string,
	countryCode: Locale,
	host: string,
	applicationAll:Array<{configKey:string,configName:string}>
}

export type GlobalVariable = BaseVariable&{}

export const useGlobal = defineStore('global', () => {
	const global = ref<GlobalVariable | {}>(uni.getStorageSync('app_global_variable') || {})
	const setGlobal = (value: BaseVariable) => {
		let _global = global.value
		_global = {..._global,...value}
		
		global.value = _global
		uni.setStorageSync('app_global_variable', _global)
	}
	return {
		global,
		setGlobal
	}
})