
let mapKey: string | null = null;
let mapKeyIndex = 0;
let keyArr: string[] = [];

const tGetMapKey = () => mapKey


const requestReject = (reject: (p: { status: number }) => void) =>
	reject({ status: 500 });

type RequestParams = {
	url: string;
	data: Record<string, any>;
};
type RequestData<T> = {
	status: number;
	result: T;
};

const Request = <T = any>({ url, data }: RequestParams) =>
	new Promise<RequestData<T>>((resolve, reject) => {
		// #ifdef MP-WEIXIN || APP-PLUS
		uni.request({
			url,
			data,
			success: (res: {
				statusCode: number;
				data: RequestData<T>;
			}) => {
				if (res.statusCode == 200) {
					if (res.data.status == 0) {
						resolve(res.data);
					} else if (res.data.status === 120 || res.data.status === 121) {
						reRequest<T>(
							{
								url,
								data,
							},
							res.data.status === 121
						)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => requestReject(reject));
					} else {
						console.error("请求错误", res);
						requestReject(reject);
					}
				} else {
					console.error("请求失败");
					requestReject(reject);
				}
			},
			fail: (err: string) => {
				console.log(err, url);
				requestReject(reject);
			},
		});
		// #endif
	});

// 当地图api状态码为120或121时，重新请求api，121代表当前使用的key每日调用量已达到上限，需切换新的key
const reRequest = <T>(
	params: {
		url: string;
		data: Record<string, any>;
	},
	switchKey: boolean
) =>
	new Promise<RequestData<T>>((resolve, reject) => {
		if (switchKey) {
			if (!keyArr.length || mapKeyIndex === keyArr.length - 1) {
				reject("error");
			} else {
				mapKeyIndex++;
				mapKey = keyArr[mapKeyIndex];
			}
		}
		Request<T>(params)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
// 逆地址解析
const tGeolocation = (latitude: number, longitude: number,isGeolocation = true) =>
	new Promise((resolve, reject) => {
		let data = {
			key: mapKey,
			location: latitude + ',' + longitude
		}
		if(isGeolocation){
			Request({
				url: 'https://apis.map.qq.com/ws/geocoder/v1',
				data: {
					...data,
					// #ifdef MP-WEIXIN || APP-PLUS
					get_poi: 1
					// #endif
				}
			})
				.then(res => {
					let {
						district,
						city,
						province
					} = res.result.address_component
					if (res.result.address_component.ad_level_1) {
						let {
							ad_level_1,
							ad_level_2,
							ad_level_3
						} = res.result.address_component
						district = ad_level_3
						city = ad_level_2
						province = ad_level_1
					}
					let data = {
						address_component: {
							district,
							city,
							province
						},
						latitude,
						longitude
					}
					uni.setStorageSync('userGeolocation', data)
					resolve(data)
				})
				.catch(err => reject(err))
		}else{
			let _data = {
				address_component: {
					district: '',
					city: '',
					province: ''
				},
				latitude,
				longitude
			}
			uni.setStorageSync('userGeolocation', _data)
			resolve(_data)
		}
		
	})
const tLoadMapScript = (arr: string[]) => {
	if (process.env.NODE_ENV === "development") {
		arr = ["YENBZ-UWUCQ-VIJ5V-G6DCI-7BDO5-RPFBJ"];
	}
	if (arr?.length > 0) {
		keyArr = arr;
		mapKey = keyArr[0];
		mapKeyIndex = 0;

		// #ifdef H5
		let script1 = document.createElement("script");
		script1.type = "text/javascript";
		script1.src = `https://map.qq.com/api/gljs?v=1.exp&key=${mapKey}`;
		document.body.appendChild(script1);
		// #endif
	}
};

// 根据地址信息转换经纬度
const tAddressToLocation = (address: string): Promise<{
	longitude: number,
	latitude: number,
}> => {
	return new Promise((resolve, reject) => {
		let data = {
			key: mapKey,
			address,
		};
		Request<{
			location: {
				lng: number;
				lat: number;
			};
		}>({
			url: "https://apis.map.qq.com/ws/geocoder/v1",
			data,
		})
			.then((res) => {
				resolve({
					longitude: res.result.location.lng,
					latitude: res.result.location.lat,
				});
			})
			.catch((err) => reject(err));
	});
};

type NearbyAddressParams = {
	latitude: number,
	longitude: number,
	keyword: string
}
// 根据地址信息转换经纬度
const tNearbyAddress = ({
	latitude, longitude,
	keyword
}: NearbyAddressParams) => {
	return new Promise((resolve) => {
		resolve([])
	})
}

export {
	tGetMapKey,
	tLoadMapScript,
	tGeolocation,
	tAddressToLocation,
	tNearbyAddress
}
