export default {
  stateTxt1: "販売中",
  stateTxt2: "売り切れ",
  stateTxt3: "倉庫中",
  stateTxt4: "審査中",
  stateTxt5: "審査不合格",
  searchTips: "商品名を入力して検索してください",
  empty: "商品がありません",
  sales: "販売数量：",
  stock: "在庫数量：",
  addGoods: "新しい商品を追加",
  down: "非公開",
  up: "公開",
  batch: "一括管理",
  checkAll: "すべて選択",
  del: "削除",
  delTips: "選択された商品を削除しますか？",
  goodsType1: "実物商品",
  goodsTypeTxt1: "（物流発送）",
  goodsType2: "バーチャル商品",
  goodsTypeTxt2: "（物流不要）",
  goodsLabe1: "タイトル",
  goodsLabe2: "商品カテゴリ",
  goodsLabe3: "共有の説明",
  goodsLabe4: "カバー画像",
  goodsLabe5: "カバー動画",
  goodsLabe6: "商品スペック/価格",
  goodsLabe7: "定価",
  goodsLabe8: "商品コード",
  goodsLabe9: "バーコード",
  goodsLabe10: "配送料",
  goodsLabe11: "販売開始時間",
  goodsLabe12: "商品詳細",
  goodsLabe13: "商品特典",
  goodsLabe14: "バーチャル販売数",
  goodsLabe15: "出荷時間",
  goodsLabe16: "販売単位数",
  goodsLabeTips1: "最大32文字のタイトルを入力してください",
  goodsLabeTips2: "商品カテゴリを選択してください",
  goodsLabeTips3: "最大36文字の説明を入力してください",
  goodsLabeTips4: "カバー画像をアップロードしてください",
  goodsLabeTips5: "商品スペックが未入力です",
  goodsLabeTips6: "定価を入力してください",
  goodsLabeTips7: "商品コードを入力してください",
  goodsLabeTips8: "バーコードを入力してください",
  goodsLabeTips9: "配送料を入力してください",
  goodsLabeTips10: "配送料テンプレートを選択してください",
  goodsLabeTips11: "販売開始日を選択してください",
  goodsLabeTips12: "バーチャル販売数を入力してください",
  goodsLabeTips13: "出荷日を選択してください",
  goodsLabeTips14: "出荷日を入力してください",
  imgTips:
    "', '15枚までのアップロードが可能です。表示効果のため、カバー画像は800*800にすることをお勧めします。'",
  videoTips:
    "主画像ビデオを追加すると、商品の成約率が向上し、より多くのトラフィックを獲得することができます。9-30秒の長さが推奨され、ビデオのサイズは商品の主画像と同じにすることがお勧めされます。",
  img: "画像",
  video: "ビデオ",
  freightTxt1: "統一運賃",
  freightTxt2: "配送料テンプレート",
  timeTxt1: "即時販売開始",
  timeTxt2: "タイマー販売開始",
  timeTxt3: "発送開始日",
  timeTxt4: "残り支払い",
  timeTxt5: "日後の発送",
  timeTips1: "日付を選択してください",
  timeTips2:
    "発送日は90日以内に設定することができます。約束した日付に出荷することを必ず守ってください。",
  rightsInterestsTips: "商品特典を選択しない場合、ユーザー側には表示されません",
  virtualTips:
    "商品詳細ページで表示される販売数量に使用されます。実際の販売やデータには影響しません",
  classlfySearch: "商品カテゴリを入力して検索してください",
  emptySku: "規格がありません",
  addSku: "新しい規格を追加",
  addSkuTips: "規格情報を入力してください",
  confirm: "確認",
  cancel: "キャンセル",
  skuName: "規格名",
  addSkuValue: "規格値を追加",
  skuValue: "規格値",
  added: "追加済み",
  sku: "規格：",
  price: "価格",
  pricePlaceholder: "価格を入力してください",
  weight: "重量",
  weightPlaceholder: "重量を入力してください",
  costPrice: "原価",
  costPlaceholder: "原価を入力してください",
  stockPlaceholder: "在庫を入力してください",
  saveSkuTips: "規格情報を完了してください",
  release: "発売する",
  reAudit: "再審査",
  skuTips: "商品スペック/価格を入力してください",
  placeholder: "詳細内容を入力してください",
  shelvesWarning: "選択した商品に未審査商品が含まれています。続行しますか？",
  shelvesError: "選択された商品は未審査であり、公開できません。",
};
