import api, { type ResponseData } from '../index'

// 获取地图服务的key
export const getMapKey = () =>
	api.get<ResponseData<string[]>>('system/api/basics/keyMap',
		{
			custom: {
				auth: false
			}
		})
// #ifdef APP-PLUS
// 获取app版本
export const getAppVersion = () =>
	api.get<ResponseData<{
		appVersionMsg: string,
		appVersion: string,
		appIosUrl: string,
		appAndroidUrl: string,
		hotUpdateUrl: string
	}>>('system/api/basics/app/version/info',
		{
			custom: {
				auth: false
			}
		}
	)
// #endif

export type addressItem = {
	id:number
	level:number
	name:string,
	pid:number
}
// 省级列表
export const getProvinceList = () =>
	api.get<ResponseData<addressItem[]>>('system/address/province',
		{
			custom: {
				auth: false
			}
		})
// 市区级列表
export const getProvinceChildList = (id:number) =>
	api.get<ResponseData<addressItem[]>>(`system/address/tree/${id}`,
		{
			custom: {
				auth: false
			}
		})

export type DictsItem = {
	dictLabel:string,
	dictValue:string
}
// 字典 
export const getDicts = (type:string) =>
  api.get<ResponseData<DictsItem[]>>(`/system/dict/data/type/${type}`);

export type UserLevelItem = {
	name:string,
	levelNumber:number,
	icon:string,
	id:number
}
// 会员等级列表
export const getUserLevelList = () =>
  api.get<{
	  code:number,msg:string,rows:UserLevelItem[]
  }>('customer/level/list');
  
export type CategoryItem = {
	icon:string
	id:number
	name:string
	parid:number
	sort:number
	type:number
}

// 主营商品类目
export const getCategoryTree = (data:{
	type?:string|number
}) =>  api.get<Array<CategoryItem>>('system/category/tree',{
	data
})


// 主营商品类目-子级列表
export const getCategoryChild = (id:number) =>  api.get<Array<CategoryItem>>(`/system/category/tree/${id}`)
