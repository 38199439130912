<script lang="ts" setup>
	import {
		baseInitDispatcher
	} from '@/common/init'
	import {
		useCurrentInstance
	} from '@/common/hooks'
	import {
		useUser,
		useIM
	} from '@/common/stores';
	import {
		onHide,
		onShow,
		onLaunch
	} from '@dcloudio/uni-app'
	import IM from '@/common/utils/IM/IM'

	const ctx = useCurrentInstance()


	onLaunch(() => {


		// 调用appInitComplete，代表项目已初始化完成，可以调用pinia存储相关的初始化变量
		baseInitDispatcher.appInitComplete()

		if (uni.getStorageSync('token')) {
			const userStores = useUser()
			const IMStore = useIM()
			userStores.getUserInfo().then(() => {
				
				if (userStores.userStoreInfo?.id) {
					userStores.getUserRouter()
					userStores.getStoreDetail().then(res => {
						// IMStore.IMLogin(res.data)
					})
					
				}
			})

		}
		console.log('App Launch')
	})
	onShow(() => {

		// 延时执行，防止小程序初始化时无法获取到数据
		setTimeout(() => {
			if (!getApp<any>().globalData.$t) {
				getApp<any>().globalData.$t = (text : string) => {
					return ctx.$t(text)
				}
			}
			if (!getApp<any>().globalData.IM) {
				getApp<any>().globalData.IM = IM
			}
		}, 2000)

	})
	onHide(() => {
		console.log('App Hide')
	})
</script>

<style lang="scss">
	/*每个页面公共css */
	@import 'common/css/index.scss';
	@import '@/uni_modules/endless-ui/index.scss';
	@import 'common/css/for.scss';
	@import "common/css/flex.scss";

	.e-button--info {
		border-color: #666 !important;
		color: #666 !important;
	}
</style>