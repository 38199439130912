import { DefaultProps, type CascaderProps, } from '../../../components/e-cascader/util'
export type Cascader = {
	show?: boolean,
	showToolbar?: boolean,
	round?: string | number,
	placeholder?: string,
	cancelText?: string,
	confirmText?: string,
	cancelColor?: string
	confirmColor?: string
	title?: string
	options?: Array<Record<any, any>>,
	props?: CascaderProps,
	valueMargin?: string | number,
	itemHeight?: string | number,
	// 视图内可见选项的数量
	visibleItemCount?: string | number,
	// 单个选项的自定义样式
	itemCustomStyle?: Record<string, any> | string,
	// 开启多选时，选项的分隔符
	separator?: string,
	// 点击遮罩是否关闭弹窗
	closeOnClickOverlay?: boolean,
	customStyle?: Record<string, any> | string,
	customClass?: string
};
export type CascaderPropsType = Cascader;
const cascader: Cascader = {
	// cascader组件
	showToolbar: true,
	round: 10,
	options: [],
	props: DefaultProps,
	valueMargin: 7,
	itemHeight: 32,
	visibleItemCount: 12,
	itemCustomStyle: {},
	separator: '/',
};
export default cascader;
