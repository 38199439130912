
export type Sliders = {
	// 未开始滑动时，滑块的文字
	startText?: string,
	endText?: string,
	bgColor?: string,
	activeBgColor?: string,
	height?: string|number,
	blockBgColor?: string,
	blockActiveBgColor ?: string,
	blockWidth?: string|number,
	blockRound?: string|number,
	autoReset?:boolean,
	duration?: string|number,
	round?: string|number,
	customStyle?: Record<string,any> |string,
	customClass?:string
};
export type SlidersPropsType = Sliders;
const sliders: Sliders = {
	// sliders组件
	bgColor:"#333333",
	height: 50,
	blockBgColor:"#3c9cff",
	blockActiveBgColor: "#fff",
	blockWidth:80,
	blockRound:4,
	autoReset :true,
	duration:0,
	round: 4,
};
export default sliders;
