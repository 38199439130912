import {AlignTypes} from '../props'
export type CollapseItem = {
	title?: string,
	value?: string,
	label?: string,
	disabled?: boolean,
	isLink?: boolean,
	clickable?: boolean,
	border?: boolean,
	align: AlignTypes,
	name?: string|number,
	icon?: string,
	duration?:number
};
export type CollapseItemPropsType = CollapseItem;
const collapseItem: CollapseItem = {
	// collapseItem组件
	title: '',
	value: '',
	label: '',
	disabled: false,
	isLink: true,
	clickable: true,
	border: true,
	align: 'left',
	name: '',
	icon: '',
	duration: 300

};
export default collapseItem;
