import { ref } from 'vue';
import { defineStore } from 'pinia'
import { getDicts,type DictsItem } from '@/common/request/api'

export const useOrder = defineStore('order', () => {
	const orderTabIndex = ref(0)
	const orderStateTxts = ref<Array<DictsItem>>([])
	const refundOrderStateTxts = ref<Array<DictsItem>>([])
	const refundOrderInfoStateTxts = ref<Array<DictsItem>>([])
	const setOrderTabIndex = (index:number) => {		
		orderTabIndex.value = index
	}
	const getOrderState = () => {
		getDicts("order_receiving_status").then((res) => {
		  if (res.code == 200) {
		   orderStateTxts.value = res.data;
		  }
		});
	}
	const getRefundOrderState = () => {
		getDicts("h_refund_sts").then((res) => {
		  if (res.code == 200) {
		   refundOrderStateTxts.value = res.data;
		  }
		});
	}
	const getRefundOrderInfoState = () => {
		getDicts("return_money_sts").then((res) => {
		  if (res.code == 200) {
		   refundOrderInfoStateTxts.value = res.data;
		  }
		});
	}
	
	return {
		orderTabIndex,
		setOrderTabIndex,
		orderStateTxts,
		getOrderState,
		getRefundOrderState,
		refundOrderStateTxts,
		getRefundOrderInfoState,
		refundOrderInfoStateTxts
	}
})