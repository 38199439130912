export default {
	tabTxt1: '待付款',
	tabTxt2: '待發貨',
	tabTxt3: '待收貨',
	tabTxt4: '待評估',
	tabTxt5: '退款/售後',
	tabTxt6: '退款中',
	tabTxt7: '退款成功',
	tabTxt8: '退款失敗',
	empty: '暫無訂單',
	orderId: '訂單編號',
	refundId: '售後編號',
	paid: '實付',
	toBePaidTxt: '等待客戶支付，超過25分鐘未支付，系統將自動取消',
	addressTxt1: '收貨地址',
	addressTxt2: '收貨人',
	goodsList: '商品列表',
	open: '展開更多',
	stow: '點擊收起',
	label1: '運費',
	label2: '已優惠',
	label3: '合計',
	label4: '訂單備註',
	label5: '訂單編號',
	label6: '下單時間',
	label7: '支付方式',
	label8: '退款原因',
	label9: '退款金額',
	label10: '申請時間',
	label11: '優惠劵金額',
	label12: '抵扣金額',
	orderInfo: '訂單資訊',
	refundInfo: '退款資訊',
	delivery: '發貨',
	deliveryTxt1: '訂單發貨',
	deliveryTxt2: '發貨管道',
	deliveryTxt3: '物流發貨',
	deliveryTxt4: '無需物流',
	deliveryTxt5: '物流編號',
	deliveryTxt6: '掃一掃條碼',
	deliveryTxt7: '物流公司',
	deliveryTxt8: '請選擇物流公司',
	deliveryTxt9: '請填寫條碼',
	info: '詳細資訊',
	agree: '同意',
	agreeTips: '確定同意退貨退款？',
	refuse: '拒絕',
	refuseTips: '確定拒絕退貨退款？',
	refuseTips1: '請輸入拒絕原因',
	refuseTxt: '拒絕原因',
	receipt: '確認收貨',
	receiptTips: '確定確認收貨嗎？',
	reject: '拒絕收貨',
	rejectTips: '確定拒絕收貨？',
	refund: '退款',
	refundTips: '確定同意退款？',
	payment: '確認打款',
	paymentTips: '確定打款給用戶嗎？',
	refundGoods: '售後商品',
	evaluation: '店鋪評分',
	reply: '回復'
}