export default {
	emptyGroup: '暂无拼团商品',
	addGroup: '添加拼团',
	oldPrice: '原价',
	price: '拼团价',
	stock: '参与库存',
	shamStock: '虚拟销售量',
	timeLimit: '组团限时',
	peopleNumber: '成团人数',
	peopleState: '虚拟成团',
	peopleNumberTips: '请输入成团人数',
	peopleNumberTips1: '拼团人数需要满足拼团才能成功',
	peopleStateTips: '开启虚拟成团后，在拼团有效期内未成团的拼团，系统会虚拟用户自动凑满人数拼团成功，虚拟的用户不生成订单，只对真实用户生成订单',
	timeLimitTips: '发起拼团需要在设置的时间内完成',
	timeLimitTips1:'请选择组团限时',
	'5m': '5分钟',
	'10m': '10分钟',
	'20m': '20分钟',
	'30m': ' 30分钟',
	'1h': '1小时',
	'2h': '2小时',
	'24h': '24小时',
	pleaseSelectProduct:'请添加商品'
}