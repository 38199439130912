export default {
	loginTitle: '商家登录',
	forgetTitle: '忘记密码',
	loginTips1: '请输入您的手机号',
	loginTips2: '请输入密码',
	login: '登录',
	forgetTips1: '请输入验证码',
	forgetTips2: '6-12位字母与数字组合',
	forgetTips3: '请确认登录密码',
	forgetTips4: '请输入登录密码',
	forgetTips5:'6位纯数字',
	forgetTips6:'请输入验证码',
	pawTips1:'请确认支付密码',
	pawTips2: '请输入支付密码',
	getCode: '获取验证码',
	label1: '手机号',
	label2: '验证码',
	label3: '登录密码',
	label4: '确认密码',
	label5: '支付密码',
	setPayPaw: '设置支付密码',
	editPayPaw: '修改支付密码',
	editLoginPaw: '修改登录密码',
	nowVersion: '当前版本',
	delCache: '清除缓存',
	delCacheTips: '是否清除APP缓存?',
	delSuccess: '清除成功',
	versionIsNew: '当前版本为最新版本',
	upgradeTxt: '正在更新...',
	upgradeSuccess: '应用已更新，即将重启',
	upgradeError: '安装失败，请重试',
	newVersion: '发现新版本',
	upgrade: '立即升级',
	unread:'未读消息：',
	allRead:'全部已读',
	goRegisterTips:'还没有账号？立即注册 >',
	goLoginTips:'已有账号？立即登录 >',
	registerTitle: '商家注册',
	agreementTxt1:'我已阅读并同意',
	agreementTxt2:'《登录协议》',
	agreementTxt3:'和',
	agreementTxt4:'《隐私政策》',
	agreementTxt5:'《入驻服务协议》',
	agreementTips:'请先阅读并同意用户协议',
	registerSuccess:'注册成功',
	forgetSuccess:'设置成功',
	switchStore:'切换店铺',
	out:'退出登录',
	outTips:'确定要退出登录吗？',
	noticeEmpty:'暂无消息',
	register:'注册',
	audio:'语音',
	yesterday:'昨天',
	album:'相册',
	goods:'商品',
	quickReply:'快捷回复',
	send:'发送',
	msgLink:'分享一个链接',
	IMPlaceholder:'请输入用户昵称搜素',
	IMEmpty:'暂无消息',
	IMSetting:'消息设置',
	quickAdd:'新增快捷回复',
	quickGroupAdd:'新建分组',
	quickGroupEdit:'编辑分组',
	quickGroupPlaceholder:'最多可输入10个字',
	quickGroupTips:'请输入分组名称',
	quickManage:'管理分组',
	delQuickGroupTips:'确定删除当前分组吗？',
	editQuickTitle:"编辑快捷回复",
	addQuickPlaceholder:'创建有效回复，能提升工作效率哦~',
	selectGroup:'选择分组',
	addQuickTips1:'请输入回复内容',
	addQuickTips2:'请选择分组',
	addQuickSuccess:'创建回复成功',
	editQuickSuccess:'回复编辑成功',
	delQuickTips:'确定删除当前回复吗？',
	selectGoods:'请选择您要发送的商品',
	searchGoodsPlaceholder:'请输入商品名称搜索',
	orderMsgTitle:'我想咨询这笔订单',
	productNotice:'产品动态',
	storeNotice:'店铺通知',
	studyNotice:'学习中心',
	readAndAgree:'请先阅读并同意相关协议'
}