export default {
	stateTxt1:'出售中',
	stateTxt2:'已售罄',
	stateTxt3:'倉庫中',
	stateTxt4:'稽核中',
	stateTxt5:'不通過',
	searchTips:'請輸入商品名稱蒐索',
	empty:'暫無商品',
	sales:'銷量：',
	stock:'庫存：',
	addGoods:'新增商品',
	down:'下架',
	up:'上架',
	batch:'批量管理',
	checkAll:'全選',
	del:'删除',
	delTips:'確定要删除選中的商品嗎？',
	goodsType1:'實物商品',
	goodsTypeTxt1:'（物流發貨）',
	goodsType2:'虛擬商品',
	goodsTypeTxt2:'（無需物流）',
	goodsLabe1:'標題',
	goodsLabe2:'商品類目',
	goodsLabe3:'分享描述',
	goodsLabe4:'封面圖',
	goodsLabe5:'封面視頻',
	goodsLabe6:'商品規格/價格',
	goodsLabe7:'劃線價格',
	goodsLabe8:'商品編碼',
	goodsLabe9:'商品條碼',
	goodsLabe10:'快遞運費',
	goodsLabe11:'開售時間',
	goodsLabe12:'商品詳情',
	goodsLabe13:'商品權益',
	goodsLabe14:'虛擬銷量',
	goodsLabe15:'發貨時間',
	goodsLabe16:'起售件數',
	goodsLabeTips1:'請輸入最多32字元的標題',
	goodsLabeTips2:'請選擇商品類目',
	goodsLabeTips3:'請輸入最多36字元的標題',
	goodsLabeTips4:'請上傳封面圖',
	goodsLabeTips5:'商品規格不能為空！',
	goodsLabeTips6:'請輸入劃線價格',
	goodsLabeTips7:'請輸入商品編碼',
	goodsLabeTips8:'請輸入商品條碼',
	goodsLabeTips9:'請填寫運費',
	goodsLabeTips10:'請選擇運費範本',
	goodsLabeTips11:'請選擇開售日期',
	goodsLabeTips12:'請輸入虛擬銷量',
	goodsLabeTips13:'請選擇發貨日期',
	goodsLabeTips14:'請填寫發貨日期',
	imgTips:'為顯示效果，封面圖建議800*800，最多上傳15張',
	videoTips:'添加主圖視頻可提升商品的成交轉化，有利於獲取更多流量，建議時長9-30秒、視頻寬高和商品主圖一致。',
	img:'圖片',
	video:'視頻',
	freightTxt1:'統一運費',
	freightTxt2:'運費範本',
	timeTxt1:'立即開售',
	timeTxt2:'定時開售',
	timeTxt3:'開始發貨',
	timeTxt4:'尾款支付',
	timeTxt5:'天后發貨',
	timeTips1:'請選擇日期',
	timeTips2:'只允許設定90天內的發貨時間，請務必按照約定時間發貨以免引起客戶投訴',
	rightsInterestsTips:'當商品權益不選擇的時候，用戶端則不顯示',
	virtualTips:'用於商品詳情頁面的銷售數量顯示，不影響實際銷售及數據',
	classlfySearch:'請輸入商品類目蒐索',
	emptySku:'暫無規格',
	addSku:'新增規格',
	addSkuTips:'請完善規格資訊，再進入下一步',
	confirm:'繼續完善',
	cancel:'退出',
	skuName:'規格名',
	addSkuValue:'添加規格值',
	skuValue:'規格值',
	added:'已添加',
	sku:'規格：',
	price:'價格',
	pricePlaceholder:'請輸入價格',
	weight:'重量',
	weightPlaceholder:'請輸入重量',
	costPrice:'成本價',
	costPlaceholder:'請輸入價格',
	stockPlaceholder:'請輸入成本價',
	saveSkuTips:'請先完善規格明細',
	release:'發佈',
	reAudit:'重新稽核',
	skuTips:'請先完善商品規格/價格',
	placeholder:'請輸入詳情內容'
}