export type Line = {
  color?: string,
  length?:string,
  direction?: "row"|"col",
  hairline?: boolean,
  margin?: number|string,
  dashed?: boolean,
  customStyle?: Record<string,any> |string,
  customClass?:string
};

export type LinePropsType = Omit<Line, "direction"> & {
  direction?: string;
};
const line: Line = {
  // line组件
  color: "#d6d7d9",
  length:"100%",
  direction: "row",
  hairline: true,
  margin: 0,
  dashed: false,
};
export default line;
